import { Search } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  accordionDetailsClasses,
  styled,
} from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMediaCollection } from '../../../../../core/domain/CBOMediaCollection';
import '../../../../../core/domain/CBOMediaCollectionPublishedPage';
import {
  CBOShopifyCollectionPage,
  CBOShopifyPage,
  CBOShopifyPagePage,
} from '../../../../../core/domain/CBOShopifyPage';
import { QueryStatus } from '../../../../../core/store/state/utils';
import UpgradePlanButton from '../../../atoms/UpgradePlanButton';
import MediaCollectionPageRow from '../../../atoms/media-collections/MediaCollectionPageRow';
import { MediaCollectionPublishedPageProduct } from '../add-published-pages-to-media-collection-dialog/AddPublishedPagesToMediaCollectionDialog';

const MediaCollectionPublishedPagesSelectorRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PagesSectionContainer = styled(Box)(({}) => ({}));

const PagesSectionTitle = styled(Typography)({
  fontWeight: 'bold',
});

const PagesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(1),
}));

const ProductSearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  paddingTop: theme.spacing(1),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&:before`]: {
    display: 'none',
  },
  border: 'none',

  marginLeft: theme.spacing(0.5),

  [`& .${accordionDetailsClasses.root}`]: {
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: 0,
}));

export type MediaCollectionPublishedPagesSelectorProps = {
  canPublishOnHomePage: boolean;
  canPublishOnPagePages: boolean;
  collectionPages: CBOShopifyCollectionPage[];
  disabled?: boolean;
  displayedProductPages: MediaCollectionPublishedPageProduct[];
  onSearchProduct: (search: string) => void;
  onSelectPages: (publishedPages: CBOShopifyPage[]) => void;
  otherMediaCollections: CBOMediaCollection[];
  pagePages: CBOShopifyPagePage[];
  productsFetchingStatus: QueryStatus;
  selectedPages: CBOShopifyPage[];
  tenantName: string;
};

const MediaCollectionPublishedPagesSelector: FC<MediaCollectionPublishedPagesSelectorProps> = ({
  canPublishOnHomePage,
  canPublishOnPagePages,
  collectionPages,
  disabled = false,
  displayedProductPages,
  onSearchProduct,
  onSelectPages,
  otherMediaCollections,
  pagePages,
  productsFetchingStatus,
  selectedPages,
  tenantName,
}) => {
  const { t } = useTranslation('mediaCollections');

  let debounceTimer: NodeJS.Timeout | null = null;

  const handleSearchProduct = (search: string) => {
    const callback = () => onSearchProduct(search);

    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = setTimeout(callback, 500);
  };

  const handleSelectPublishedPage = useCallback(
    (publishedPage: CBOShopifyPage, isSelected: boolean) => {
      const newSelectedPages = isSelected
        ? [...selectedPages, publishedPage]
        : selectedPages.filter((selectedPage) => selectedPage.id !== publishedPage.id);

      onSelectPages(newSelectedPages);
    },
    [selectedPages],
  );

  const renderHomePage = () => {
    const isSelected = selectedPages.some(({ type }) => type === 'home');

    const alreadyPublishedInCollection = otherMediaCollections.find((collection) =>
      collection.publishedPages.some(({ type }) => type === 'home'),
    );

    const isPublishOnHomePageRestricted = !canPublishOnHomePage && !isSelected;

    let cannotPublishMessage: string | undefined;
    let additionalActions: React.ReactNode | undefined;

    if (alreadyPublishedInCollection) {
      cannotPublishMessage = t('AlreadyDisplayedCollection', {
        alreadyPublishedInCollectionTitle: alreadyPublishedInCollection.title,
      });
    }

    if (isPublishOnHomePageRestricted) {
      additionalActions = (
        <UpgradePlanButton
          ButtonProps={{ size: 'small' }}
          sx={{ fontSize: '.9em', marginLeft: 2 }}
          tenantName={tenantName}
        />
      );
      cannotPublishMessage = t('CannotPublishOnHomePage');
    }

    return (
      <MediaCollectionPageRow
        additionalActions={additionalActions}
        cannotPublishMessage={cannotPublishMessage}
        image={null}
        isDisabled={disabled}
        isSelected={isSelected}
        key="home"
        label={t('HomePage')}
        onChange={(isSelected) => handleSelectPublishedPage({ id: 'home', type: 'home' }, isSelected)}
      />
    );
  };

  const renderProductPage = (productPage: MediaCollectionPublishedPageProduct) => {
    const isSelected = selectedPages.some(({ id, type }) => type === 'product' && id === productPage.id);
    const alreadyPublishedInCollection = otherMediaCollections.find((collection) =>
      collection.publishedPages.some(({ id, type }) => type === 'product' && id === productPage.id),
    );

    let cannotPublishMessage: string | undefined;

    if (alreadyPublishedInCollection) {
      cannotPublishMessage = t('AlreadyDisplayedCollection', {
        alreadyPublishedInCollectionTitle: alreadyPublishedInCollection.title,
      });
    }
    return (
      <MediaCollectionPageRow
        cannotPublishMessage={cannotPublishMessage}
        image={productPage.image}
        isDisabled={disabled}
        isSelected={isSelected}
        key={productPage.id}
        label={productPage.title}
        onChange={(isSelected) =>
          handleSelectPublishedPage(
            { id: productPage.id, image: productPage.image, title: productPage.title, type: 'product' },
            isSelected,
          )
        }
      />
    );
  };

  const renderCollectionPage = (page: CBOShopifyCollectionPage) => {
    const isSelected = selectedPages.some(({ id, type }) => type === 'collection' && id === page.id);
    const alreadyPublishedInCollection = otherMediaCollections.find((collection) =>
      collection.publishedPages.some(({ id, type }) => type === 'collection' && id === page.id),
    );
    let cannotPublishMessage: string | undefined;

    if (alreadyPublishedInCollection) {
      cannotPublishMessage = t('AlreadyDisplayedCollection', {
        alreadyPublishedInCollectionTitle: alreadyPublishedInCollection.title,
      });
    }

    return (
      <Box key={page.id} ml={0.5}>
        <MediaCollectionPageRow
          cannotPublishMessage={cannotPublishMessage}
          image={null}
          isDisabled={disabled}
          isSelected={isSelected}
          label={page.title}
          onChange={(isSelected) => handleSelectPublishedPage(page, isSelected)}
        />
      </Box>
    );
  };

  const renderPagePage = (page: CBOShopifyPagePage) => {
    const isSelected = selectedPages.some(({ id, type }) => type === 'page' && id === page.id);
    const alreadyPublishedInCollection = otherMediaCollections.find((collection) =>
      collection.publishedPages.some(({ id, type }) => type === 'page' && id === page.id),
    );
    let cannotPublishMessage: string | undefined;

    if (alreadyPublishedInCollection) {
      cannotPublishMessage = t('AlreadyDisplayedCollection', {
        alreadyPublishedInCollectionTitle: alreadyPublishedInCollection.title,
      });
    }

    return (
      <Box key={page.id} ml={0.5}>
        <MediaCollectionPageRow
          cannotPublishMessage={cannotPublishMessage}
          image={null}
          isDisabled={disabled}
          isSelected={isSelected}
          label={page.title}
          onChange={(isSelected) => handleSelectPublishedPage(page, isSelected)}
        />
      </Box>
    );
  };

  const renderLoader = () => (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" p={2}>
      <CircularProgress />
    </Box>
  );

  return (
    <MediaCollectionPublishedPagesSelectorRoot>
      {renderHomePage()}
      <StyledAccordion defaultExpanded disableGutters variant="outlined">
        <StyledAccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <PagesSectionTitle>{t('ProductPages')}</PagesSectionTitle>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <PagesSectionContainer>
            <ProductSearchContainer>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                label={t('SearchProduct')}
                onChange={(e) => handleSearchProduct(e.target.value)}
                size="small"
              />
            </ProductSearchContainer>
            {productsFetchingStatus === 'pending' ? (
              renderLoader()
            ) : (
              <PagesContainer gap={0} py={2}>
                {displayedProductPages.map(renderProductPage)}
              </PagesContainer>
            )}
          </PagesSectionContainer>
        </AccordionDetails>
      </StyledAccordion>
      {collectionPages.length > 0 && (
        <StyledAccordion defaultExpanded disableGutters variant="outlined">
          <StyledAccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <PagesSectionTitle>{t('CollectionPages')}</PagesSectionTitle>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <PagesSectionContainer>
              <PagesContainer>{collectionPages.map(renderCollectionPage)}</PagesContainer>
            </PagesSectionContainer>
          </AccordionDetails>
        </StyledAccordion>
      )}
      {pagePages.length > 0 && canPublishOnPagePages && (
        <StyledAccordion defaultExpanded disableGutters variant="outlined">
          <StyledAccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <PagesSectionTitle>{t('PagePages')}</PagesSectionTitle>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <PagesSectionContainer>
              <PagesContainer>{pagePages.map(renderPagePage)}</PagesContainer>
            </PagesSectionContainer>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </MediaCollectionPublishedPagesSelectorRoot>
  );
};

export default MediaCollectionPublishedPagesSelector;
