var FirestoreCollectionNames = /* @__PURE__ */ ((FirestoreCollectionNames2) => {
  FirestoreCollectionNames2["eventReadModels"] = "eventReadModels";
  FirestoreCollectionNames2["liveShows"] = "live-shows";
  FirestoreCollectionNames2["shoppableList"] = "shoppable-list";
  FirestoreCollectionNames2["tenants"] = "tenants";
  FirestoreCollectionNames2["tenantsPublic"] = "tenants-public";
  return FirestoreCollectionNames2;
})(FirestoreCollectionNames || {});
const Device = {
  Mobile: "MOBILE",
  Tablet: "TABLET",
  Desktop: "DESKTOP"
};
var ErrorCodes = /* @__PURE__ */ ((ErrorCodes2) => {
  ErrorCodes2["FrozenEvent"] = "60001";
  ErrorCodes2["PublisherPhoneRequired"] = "60002";
  ErrorCodes2["NotStreaming"] = "60003";
  ErrorCodes2["ActiveSubscriptionRequired"] = "60004";
  ErrorCodes2["StreamingRequiredForRestreaming"] = "60005";
  ErrorCodes2["ExpiredFacebookToken"] = "60006";
  ErrorCodes2["ExpiredYoutubeToken"] = "60007";
  return ErrorCodes2;
})(ErrorCodes || {});
const ErrorsByCode = {
  [
    "60004"
    /* ActiveSubscriptionRequired */
  ]: "ActiveSubscriptionRequired",
  [
    "60001"
    /* FrozenEvent */
  ]: "FrozenEvent",
  [
    "60003"
    /* NotStreaming */
  ]: "NotStreaming",
  [
    "60002"
    /* PublisherPhoneRequired */
  ]: "PublisherPhoneRequired",
  [
    "60005"
    /* StreamingRequiredForRestreaming */
  ]: "StreamingRequiredForRestreaming",
  [
    "60006"
    /* ExpiredFacebookToken */
  ]: "ExpiredFacebookToken",
  [
    "60007"
    /* ExpiredYoutubeToken */
  ]: "ExpiredYoutubeToken"
};
var util;
(function(util2) {
  util2.assertEqual = (val) => val;
  function assertIs(_arg) {
  }
  util2.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util2.assertNever = assertNever;
  util2.arrayToEnum = (items) => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util2.getValidEnumValues = (obj) => {
    const validKeys = util2.objectKeys(obj).filter((k) => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util2.objectValues(filtered);
  };
  util2.objectValues = (obj) => {
    return util2.objectKeys(obj).map(function(e) {
      return obj[e];
    });
  };
  util2.objectKeys = typeof Object.keys === "function" ? (obj) => Object.keys(obj) : (object) => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util2.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item))
        return item;
    }
    return void 0;
  };
  util2.isInteger = typeof Number.isInteger === "function" ? (val) => Number.isInteger(val) : (val) => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util2.joinValues = joinValues;
  util2.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util || (util = {}));
var objectUtil;
(function(objectUtil2) {
  objectUtil2.mergeShapes = (first, second) => {
    return {
      ...first,
      ...second
      // second overwrites first
    };
  };
})(objectUtil || (objectUtil = {}));
const ZodParsedType = util.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]);
const getParsedType = (data) => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return isNaN(data) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "symbol":
      return ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return ZodParsedType.array;
      }
      if (data === null) {
        return ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return ZodParsedType.date;
      }
      return ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
};
const ZodIssueCode = util.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]);
const quotelessJson = (obj) => {
  const json = JSON.stringify(obj, null, 2);
  return json.replace(/"([^"]+)":/g, "$1:");
};
class ZodError extends Error {
  constructor(issues) {
    super();
    this.issues = [];
    this.addIssue = (sub) => {
      this.issues = [...this.issues, sub];
    };
    this.addIssues = (subs = []) => {
      this.issues = [...this.issues, ...subs];
    };
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
    this.name = "ZodError";
    this.issues = issues;
  }
  get errors() {
    return this.issues;
  }
  format(_mapper) {
    const mapper = _mapper || function(issue) {
      return issue.message;
    };
    const fieldErrors = { _errors: [] };
    const processError = (error) => {
      for (const issue of error.issues) {
        if (issue.code === "invalid_union") {
          issue.unionErrors.map(processError);
        } else if (issue.code === "invalid_return_type") {
          processError(issue.returnTypeError);
        } else if (issue.code === "invalid_arguments") {
          processError(issue.argumentsError);
        } else if (issue.path.length === 0) {
          fieldErrors._errors.push(mapper(issue));
        } else {
          let curr = fieldErrors;
          let i = 0;
          while (i < issue.path.length) {
            const el = issue.path[i];
            const terminal = i === issue.path.length - 1;
            if (!terminal) {
              curr[el] = curr[el] || { _errors: [] };
            } else {
              curr[el] = curr[el] || { _errors: [] };
              curr[el]._errors.push(mapper(issue));
            }
            curr = curr[el];
            i++;
          }
        }
      }
    };
    processError(this);
    return fieldErrors;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, util.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(mapper = (issue) => issue.message) {
    const fieldErrors = {};
    const formErrors = [];
    for (const sub of this.issues) {
      if (sub.path.length > 0) {
        fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
        fieldErrors[sub.path[0]].push(mapper(sub));
      } else {
        formErrors.push(mapper(sub));
      }
    }
    return { formErrors, fieldErrors };
  }
  get formErrors() {
    return this.flatten();
  }
}
ZodError.create = (issues) => {
  const error = new ZodError(issues);
  return error;
};
const errorMap = (issue, _ctx) => {
  let message;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = "Required";
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `Invalid literal value, expected ${JSON.stringify(issue.expected, util.jsonStringifyReplacer)}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${util.joinValues(issue.keys, ", ")}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `Invalid discriminator value. Expected ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${util.joinValues(issue.options)}, received '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("includes" in issue.validation) {
          message = `Invalid input: must include "${issue.validation.includes}"`;
          if (typeof issue.validation.position === "number") {
            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
          }
        } else if ("startsWith" in issue.validation) {
          message = `Invalid input: must start with "${issue.validation.startsWith}"`;
        } else if ("endsWith" in issue.validation) {
          message = `Invalid input: must end with "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== "regex") {
        message = `Invalid ${issue.validation}`;
      } else {
        message = "Invalid";
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${issue.minimum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${new Date(Number(issue.minimum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.too_big:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "bigint")
        message = `BigInt must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.custom:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Intersection results could not be merged`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = "Number must be finite";
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};
let overrideErrorMap = errorMap;
function setErrorMap(map) {
  overrideErrorMap = map;
}
function getErrorMap() {
  return overrideErrorMap;
}
const makeIssue = (params) => {
  const { data, path, errorMaps, issueData } = params;
  const fullPath = [...path, ...issueData.path || []];
  const fullIssue = {
    ...issueData,
    path: fullPath
  };
  let errorMessage = "";
  const maps = errorMaps.filter((m) => !!m).slice().reverse();
  for (const map of maps) {
    errorMessage = map(fullIssue, { data, defaultError: errorMessage }).message;
  }
  return {
    ...issueData,
    path: fullPath,
    message: issueData.message || errorMessage
  };
};
const EMPTY_PATH = [];
function addIssueToContext(ctx, issueData) {
  const issue = makeIssue({
    issueData,
    data: ctx.data,
    path: ctx.path,
    errorMaps: [
      ctx.common.contextualErrorMap,
      ctx.schemaErrorMap,
      getErrorMap(),
      errorMap
      // then global default map
    ].filter((x) => !!x)
  });
  ctx.common.issues.push(issue);
}
class ParseStatus {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    if (this.value === "valid")
      this.value = "dirty";
  }
  abort() {
    if (this.value !== "aborted")
      this.value = "aborted";
  }
  static mergeArray(status, results) {
    const arrayValue = [];
    for (const s of results) {
      if (s.status === "aborted")
        return INVALID;
      if (s.status === "dirty")
        status.dirty();
      arrayValue.push(s.value);
    }
    return { status: status.value, value: arrayValue };
  }
  static async mergeObjectAsync(status, pairs) {
    const syncPairs = [];
    for (const pair of pairs) {
      syncPairs.push({
        key: await pair.key,
        value: await pair.value
      });
    }
    return ParseStatus.mergeObjectSync(status, syncPairs);
  }
  static mergeObjectSync(status, pairs) {
    const finalObject = {};
    for (const pair of pairs) {
      const { key, value } = pair;
      if (key.status === "aborted")
        return INVALID;
      if (value.status === "aborted")
        return INVALID;
      if (key.status === "dirty")
        status.dirty();
      if (value.status === "dirty")
        status.dirty();
      if (typeof value.value !== "undefined" || pair.alwaysSet) {
        finalObject[key.value] = value.value;
      }
    }
    return { status: status.value, value: finalObject };
  }
}
const INVALID = Object.freeze({
  status: "aborted"
});
const DIRTY = (value) => ({ status: "dirty", value });
const OK = (value) => ({ status: "valid", value });
const isAborted = (x) => x.status === "aborted";
const isDirty = (x) => x.status === "dirty";
const isValid = (x) => x.status === "valid";
const isAsync = (x) => typeof Promise !== "undefined" && x instanceof Promise;
var errorUtil;
(function(errorUtil2) {
  errorUtil2.errToObj = (message) => typeof message === "string" ? { message } : message || {};
  errorUtil2.toString = (message) => typeof message === "string" ? message : message === null || message === void 0 ? void 0 : message.message;
})(errorUtil || (errorUtil = {}));
class ParseInputLazyPath {
  constructor(parent, value, path, key) {
    this._cachedPath = [];
    this.parent = parent;
    this.data = value;
    this._path = path;
    this._key = key;
  }
  get path() {
    if (!this._cachedPath.length) {
      if (this._key instanceof Array) {
        this._cachedPath.push(...this._path, ...this._key);
      } else {
        this._cachedPath.push(...this._path, this._key);
      }
    }
    return this._cachedPath;
  }
}
const handleResult = (ctx, result) => {
  if (isValid(result)) {
    return { success: true, data: result.value };
  } else {
    if (!ctx.common.issues.length) {
      throw new Error("Validation failed but no issues detected.");
    }
    return {
      success: false,
      get error() {
        if (this._error)
          return this._error;
        const error = new ZodError(ctx.common.issues);
        this._error = error;
        return this._error;
      }
    };
  }
};
function processCreateParams(params) {
  if (!params)
    return {};
  const { errorMap: errorMap2, invalid_type_error, required_error, description } = params;
  if (errorMap2 && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap2)
    return { errorMap: errorMap2, description };
  const customMap = (iss, ctx) => {
    if (iss.code !== "invalid_type")
      return { message: ctx.defaultError };
    if (typeof ctx.data === "undefined") {
      return { message: required_error !== null && required_error !== void 0 ? required_error : ctx.defaultError };
    }
    return { message: invalid_type_error !== null && invalid_type_error !== void 0 ? invalid_type_error : ctx.defaultError };
  };
  return { errorMap: customMap, description };
}
class ZodType {
  constructor(def) {
    this.spa = this.safeParseAsync;
    this._def = def;
    this.parse = this.parse.bind(this);
    this.safeParse = this.safeParse.bind(this);
    this.parseAsync = this.parseAsync.bind(this);
    this.safeParseAsync = this.safeParseAsync.bind(this);
    this.spa = this.spa.bind(this);
    this.refine = this.refine.bind(this);
    this.refinement = this.refinement.bind(this);
    this.superRefine = this.superRefine.bind(this);
    this.optional = this.optional.bind(this);
    this.nullable = this.nullable.bind(this);
    this.nullish = this.nullish.bind(this);
    this.array = this.array.bind(this);
    this.promise = this.promise.bind(this);
    this.or = this.or.bind(this);
    this.and = this.and.bind(this);
    this.transform = this.transform.bind(this);
    this.brand = this.brand.bind(this);
    this.default = this.default.bind(this);
    this.catch = this.catch.bind(this);
    this.describe = this.describe.bind(this);
    this.pipe = this.pipe.bind(this);
    this.isNullable = this.isNullable.bind(this);
    this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(input) {
    return getParsedType(input.data);
  }
  _getOrReturnCtx(input, ctx) {
    return ctx || {
      common: input.parent.common,
      data: input.data,
      parsedType: getParsedType(input.data),
      schemaErrorMap: this._def.errorMap,
      path: input.path,
      parent: input.parent
    };
  }
  _processInputParams(input) {
    return {
      status: new ParseStatus(),
      ctx: {
        common: input.parent.common,
        data: input.data,
        parsedType: getParsedType(input.data),
        schemaErrorMap: this._def.errorMap,
        path: input.path,
        parent: input.parent
      }
    };
  }
  _parseSync(input) {
    const result = this._parse(input);
    if (isAsync(result)) {
      throw new Error("Synchronous parse encountered promise.");
    }
    return result;
  }
  _parseAsync(input) {
    const result = this._parse(input);
    return Promise.resolve(result);
  }
  parse(data, params) {
    const result = this.safeParse(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  safeParse(data, params) {
    var _a;
    const ctx = {
      common: {
        issues: [],
        async: (_a = params === null || params === void 0 ? void 0 : params.async) !== null && _a !== void 0 ? _a : false,
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const result = this._parseSync({ data, path: ctx.path, parent: ctx });
    return handleResult(ctx, result);
  }
  async parseAsync(data, params) {
    const result = await this.safeParseAsync(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  async safeParseAsync(data, params) {
    const ctx = {
      common: {
        issues: [],
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap,
        async: true
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const maybeAsyncResult = this._parse({ data, path: ctx.path, parent: ctx });
    const result = await (isAsync(maybeAsyncResult) ? maybeAsyncResult : Promise.resolve(maybeAsyncResult));
    return handleResult(ctx, result);
  }
  refine(check, message) {
    const getIssueProperties = (val) => {
      if (typeof message === "string" || typeof message === "undefined") {
        return { message };
      } else if (typeof message === "function") {
        return message(val);
      } else {
        return message;
      }
    };
    return this._refinement((val, ctx) => {
      const result = check(val);
      const setError = () => ctx.addIssue({
        code: ZodIssueCode.custom,
        ...getIssueProperties(val)
      });
      if (typeof Promise !== "undefined" && result instanceof Promise) {
        return result.then((data) => {
          if (!data) {
            setError();
            return false;
          } else {
            return true;
          }
        });
      }
      if (!result) {
        setError();
        return false;
      } else {
        return true;
      }
    });
  }
  refinement(check, refinementData) {
    return this._refinement((val, ctx) => {
      if (!check(val)) {
        ctx.addIssue(typeof refinementData === "function" ? refinementData(val, ctx) : refinementData);
        return false;
      } else {
        return true;
      }
    });
  }
  _refinement(refinement) {
    return new ZodEffects({
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "refinement", refinement }
    });
  }
  superRefine(refinement) {
    return this._refinement(refinement);
  }
  optional() {
    return ZodOptional.create(this, this._def);
  }
  nullable() {
    return ZodNullable.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return ZodArray.create(this, this._def);
  }
  promise() {
    return ZodPromise.create(this, this._def);
  }
  or(option) {
    return ZodUnion.create([this, option], this._def);
  }
  and(incoming) {
    return ZodIntersection.create(this, incoming, this._def);
  }
  transform(transform) {
    return new ZodEffects({
      ...processCreateParams(this._def),
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "transform", transform }
    });
  }
  default(def) {
    const defaultValueFunc = typeof def === "function" ? def : () => def;
    return new ZodDefault({
      ...processCreateParams(this._def),
      innerType: this,
      defaultValue: defaultValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodDefault
    });
  }
  brand() {
    return new ZodBranded({
      typeName: ZodFirstPartyTypeKind.ZodBranded,
      type: this,
      ...processCreateParams(this._def)
    });
  }
  catch(def) {
    const catchValueFunc = typeof def === "function" ? def : () => def;
    return new ZodCatch({
      ...processCreateParams(this._def),
      innerType: this,
      catchValue: catchValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodCatch
    });
  }
  describe(description) {
    const This = this.constructor;
    return new This({
      ...this._def,
      description
    });
  }
  pipe(target) {
    return ZodPipeline.create(this, target);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const cuidRegex = /^c[^\s-]{8,}$/i;
const cuid2Regex = /^[a-z][a-z0-9]*$/;
const ulidRegex = /[0-9A-HJKMNP-TV-Z]{26}/;
const uuidRegex = /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$/i;
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,})+))$/;
const emojiRegex = new RegExp("^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$", "u");
const ipv4Regex = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/;
const ipv6Regex = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/;
const datetimeRegex = (args) => {
  if (args.precision) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}Z$`);
    }
  } else if (args.precision === 0) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$`);
    }
  } else {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$`);
    }
  }
};
function isValidIP(ip, version) {
  if ((version === "v4" || !version) && ipv4Regex.test(ip)) {
    return true;
  }
  if ((version === "v6" || !version) && ipv6Regex.test(ip)) {
    return true;
  }
  return false;
}
class ZodString extends ZodType {
  constructor() {
    super(...arguments);
    this._regex = (regex, validation, message) => this.refinement((data) => regex.test(data), {
      validation,
      code: ZodIssueCode.invalid_string,
      ...errorUtil.errToObj(message)
    });
    this.nonempty = (message) => this.min(1, errorUtil.errToObj(message));
    this.trim = () => new ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
    this.toLowerCase = () => new ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
    this.toUpperCase = () => new ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = String(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.string) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(
        ctx2,
        {
          code: ZodIssueCode.invalid_type,
          expected: ZodParsedType.string,
          received: ctx2.parsedType
        }
        //
      );
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.length < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.length > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "length") {
        const tooBig = input.data.length > check.value;
        const tooSmall = input.data.length < check.value;
        if (tooBig || tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          if (tooBig) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_big,
              maximum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          } else if (tooSmall) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_small,
              minimum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          }
          status.dirty();
        }
      } else if (check.kind === "email") {
        if (!emailRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "email",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "emoji") {
        if (!emojiRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "emoji",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "uuid") {
        if (!uuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "uuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid") {
        if (!cuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid2") {
        if (!cuid2Regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid2",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ulid") {
        if (!ulidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ulid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "url") {
        try {
          new URL(input.data);
        } catch (_a) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "url",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "regex") {
        check.regex.lastIndex = 0;
        const testResult = check.regex.test(input.data);
        if (!testResult) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "regex",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "trim") {
        input.data = input.data.trim();
      } else if (check.kind === "includes") {
        if (!input.data.includes(check.value, check.position)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { includes: check.value, position: check.position },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "toLowerCase") {
        input.data = input.data.toLowerCase();
      } else if (check.kind === "toUpperCase") {
        input.data = input.data.toUpperCase();
      } else if (check.kind === "startsWith") {
        if (!input.data.startsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { startsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "endsWith") {
        if (!input.data.endsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { endsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "datetime") {
        const regex = datetimeRegex(check);
        if (!regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: "datetime",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ip") {
        if (!isValidIP(input.data, check.version)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ip",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  _addCheck(check) {
    return new ZodString({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  email(message) {
    return this._addCheck({ kind: "email", ...errorUtil.errToObj(message) });
  }
  url(message) {
    return this._addCheck({ kind: "url", ...errorUtil.errToObj(message) });
  }
  emoji(message) {
    return this._addCheck({ kind: "emoji", ...errorUtil.errToObj(message) });
  }
  uuid(message) {
    return this._addCheck({ kind: "uuid", ...errorUtil.errToObj(message) });
  }
  cuid(message) {
    return this._addCheck({ kind: "cuid", ...errorUtil.errToObj(message) });
  }
  cuid2(message) {
    return this._addCheck({ kind: "cuid2", ...errorUtil.errToObj(message) });
  }
  ulid(message) {
    return this._addCheck({ kind: "ulid", ...errorUtil.errToObj(message) });
  }
  ip(options) {
    return this._addCheck({ kind: "ip", ...errorUtil.errToObj(options) });
  }
  datetime(options) {
    var _a;
    if (typeof options === "string") {
      return this._addCheck({
        kind: "datetime",
        precision: null,
        offset: false,
        message: options
      });
    }
    return this._addCheck({
      kind: "datetime",
      precision: typeof (options === null || options === void 0 ? void 0 : options.precision) === "undefined" ? null : options === null || options === void 0 ? void 0 : options.precision,
      offset: (_a = options === null || options === void 0 ? void 0 : options.offset) !== null && _a !== void 0 ? _a : false,
      ...errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)
    });
  }
  regex(regex, message) {
    return this._addCheck({
      kind: "regex",
      regex,
      ...errorUtil.errToObj(message)
    });
  }
  includes(value, options) {
    return this._addCheck({
      kind: "includes",
      value,
      position: options === null || options === void 0 ? void 0 : options.position,
      ...errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)
    });
  }
  startsWith(value, message) {
    return this._addCheck({
      kind: "startsWith",
      value,
      ...errorUtil.errToObj(message)
    });
  }
  endsWith(value, message) {
    return this._addCheck({
      kind: "endsWith",
      value,
      ...errorUtil.errToObj(message)
    });
  }
  min(minLength, message) {
    return this._addCheck({
      kind: "min",
      value: minLength,
      ...errorUtil.errToObj(message)
    });
  }
  max(maxLength, message) {
    return this._addCheck({
      kind: "max",
      value: maxLength,
      ...errorUtil.errToObj(message)
    });
  }
  length(len, message) {
    return this._addCheck({
      kind: "length",
      value: len,
      ...errorUtil.errToObj(message)
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((ch) => ch.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((ch) => ch.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((ch) => ch.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((ch) => ch.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((ch) => ch.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((ch) => ch.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((ch) => ch.kind === "ip");
  }
  get minLength() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxLength() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
}
ZodString.create = (params) => {
  var _a;
  return new ZodString({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodString,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false,
    ...processCreateParams(params)
  });
};
function floatSafeRemainder(val, step) {
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return valInt % stepInt / Math.pow(10, decCount);
}
class ZodNumber extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
    this.step = this.multipleOf;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = Number(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.number) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.number,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "int") {
        if (!util.isInteger(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_type,
            expected: "integer",
            received: "float",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (floatSafeRemainder(input.data, check.value) !== 0) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "finite") {
        if (!Number.isFinite(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_finite,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodNumber({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    });
  }
  _addCheck(check) {
    return new ZodNumber({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  int(message) {
    return this._addCheck({
      kind: "int",
      message: errorUtil.toString(message)
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  finite(message) {
    return this._addCheck({
      kind: "finite",
      message: errorUtil.toString(message)
    });
  }
  safe(message) {
    return this._addCheck({
      kind: "min",
      inclusive: true,
      value: Number.MIN_SAFE_INTEGER,
      message: errorUtil.toString(message)
    })._addCheck({
      kind: "max",
      inclusive: true,
      value: Number.MAX_SAFE_INTEGER,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
  get isInt() {
    return !!this._def.checks.find((ch) => ch.kind === "int" || ch.kind === "multipleOf" && util.isInteger(ch.value));
  }
  get isFinite() {
    let max = null, min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "finite" || ch.kind === "int" || ch.kind === "multipleOf") {
        return true;
      } else if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      } else if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return Number.isFinite(min) && Number.isFinite(max);
  }
}
ZodNumber.create = (params) => {
  return new ZodNumber({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodNumber,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    ...processCreateParams(params)
  });
};
class ZodBigInt extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = BigInt(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.bigint) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.bigint,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            type: "bigint",
            minimum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            type: "bigint",
            maximum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (input.data % check.value !== BigInt(0)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodBigInt({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    });
  }
  _addCheck(check) {
    return new ZodBigInt({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
}
ZodBigInt.create = (params) => {
  var _a;
  return new ZodBigInt({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodBigInt,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false,
    ...processCreateParams(params)
  });
};
class ZodBoolean extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = Boolean(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.boolean) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.boolean,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
}
ZodBoolean.create = (params) => {
  return new ZodBoolean({
    typeName: ZodFirstPartyTypeKind.ZodBoolean,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    ...processCreateParams(params)
  });
};
class ZodDate extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = new Date(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.date) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.date,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    if (isNaN(input.data.getTime())) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_date
      });
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.getTime() < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            message: check.message,
            inclusive: true,
            exact: false,
            minimum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.getTime() > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            message: check.message,
            inclusive: true,
            exact: false,
            maximum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return {
      status: status.value,
      value: new Date(input.data.getTime())
    };
  }
  _addCheck(check) {
    return new ZodDate({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  min(minDate, message) {
    return this._addCheck({
      kind: "min",
      value: minDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  max(maxDate, message) {
    return this._addCheck({
      kind: "max",
      value: maxDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  get minDate() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min != null ? new Date(min) : null;
  }
  get maxDate() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max != null ? new Date(max) : null;
  }
}
ZodDate.create = (params) => {
  return new ZodDate({
    checks: [],
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    typeName: ZodFirstPartyTypeKind.ZodDate,
    ...processCreateParams(params)
  });
};
class ZodSymbol extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.symbol) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.symbol,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
}
ZodSymbol.create = (params) => {
  return new ZodSymbol({
    typeName: ZodFirstPartyTypeKind.ZodSymbol,
    ...processCreateParams(params)
  });
};
class ZodUndefined extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.undefined,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
}
ZodUndefined.create = (params) => {
  return new ZodUndefined({
    typeName: ZodFirstPartyTypeKind.ZodUndefined,
    ...processCreateParams(params)
  });
};
class ZodNull extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.null) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.null,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
}
ZodNull.create = (params) => {
  return new ZodNull({
    typeName: ZodFirstPartyTypeKind.ZodNull,
    ...processCreateParams(params)
  });
};
class ZodAny extends ZodType {
  constructor() {
    super(...arguments);
    this._any = true;
  }
  _parse(input) {
    return OK(input.data);
  }
}
ZodAny.create = (params) => {
  return new ZodAny({
    typeName: ZodFirstPartyTypeKind.ZodAny,
    ...processCreateParams(params)
  });
};
class ZodUnknown extends ZodType {
  constructor() {
    super(...arguments);
    this._unknown = true;
  }
  _parse(input) {
    return OK(input.data);
  }
}
ZodUnknown.create = (params) => {
  return new ZodUnknown({
    typeName: ZodFirstPartyTypeKind.ZodUnknown,
    ...processCreateParams(params)
  });
};
class ZodNever extends ZodType {
  _parse(input) {
    const ctx = this._getOrReturnCtx(input);
    addIssueToContext(ctx, {
      code: ZodIssueCode.invalid_type,
      expected: ZodParsedType.never,
      received: ctx.parsedType
    });
    return INVALID;
  }
}
ZodNever.create = (params) => {
  return new ZodNever({
    typeName: ZodFirstPartyTypeKind.ZodNever,
    ...processCreateParams(params)
  });
};
class ZodVoid extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.void,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
}
ZodVoid.create = (params) => {
  return new ZodVoid({
    typeName: ZodFirstPartyTypeKind.ZodVoid,
    ...processCreateParams(params)
  });
};
class ZodArray extends ZodType {
  _parse(input) {
    const { ctx, status } = this._processInputParams(input);
    const def = this._def;
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (def.exactLength !== null) {
      const tooBig = ctx.data.length > def.exactLength.value;
      const tooSmall = ctx.data.length < def.exactLength.value;
      if (tooBig || tooSmall) {
        addIssueToContext(ctx, {
          code: tooBig ? ZodIssueCode.too_big : ZodIssueCode.too_small,
          minimum: tooSmall ? def.exactLength.value : void 0,
          maximum: tooBig ? def.exactLength.value : void 0,
          type: "array",
          inclusive: true,
          exact: true,
          message: def.exactLength.message
        });
        status.dirty();
      }
    }
    if (def.minLength !== null) {
      if (ctx.data.length < def.minLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.minLength.message
        });
        status.dirty();
      }
    }
    if (def.maxLength !== null) {
      if (ctx.data.length > def.maxLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.maxLength.message
        });
        status.dirty();
      }
    }
    if (ctx.common.async) {
      return Promise.all([...ctx.data].map((item, i) => {
        return def.type._parseAsync(new ParseInputLazyPath(ctx, item, ctx.path, i));
      })).then((result2) => {
        return ParseStatus.mergeArray(status, result2);
      });
    }
    const result = [...ctx.data].map((item, i) => {
      return def.type._parseSync(new ParseInputLazyPath(ctx, item, ctx.path, i));
    });
    return ParseStatus.mergeArray(status, result);
  }
  get element() {
    return this._def.type;
  }
  min(minLength, message) {
    return new ZodArray({
      ...this._def,
      minLength: { value: minLength, message: errorUtil.toString(message) }
    });
  }
  max(maxLength, message) {
    return new ZodArray({
      ...this._def,
      maxLength: { value: maxLength, message: errorUtil.toString(message) }
    });
  }
  length(len, message) {
    return new ZodArray({
      ...this._def,
      exactLength: { value: len, message: errorUtil.toString(message) }
    });
  }
  nonempty(message) {
    return this.min(1, message);
  }
}
ZodArray.create = (schema, params) => {
  return new ZodArray({
    type: schema,
    minLength: null,
    maxLength: null,
    exactLength: null,
    typeName: ZodFirstPartyTypeKind.ZodArray,
    ...processCreateParams(params)
  });
};
function deepPartialify(schema) {
  if (schema instanceof ZodObject) {
    const newShape = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = ZodOptional.create(deepPartialify(fieldSchema));
    }
    return new ZodObject({
      ...schema._def,
      shape: () => newShape
    });
  } else if (schema instanceof ZodArray) {
    return new ZodArray({
      ...schema._def,
      type: deepPartialify(schema.element)
    });
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item) => deepPartialify(item)));
  } else {
    return schema;
  }
}
class ZodObject extends ZodType {
  constructor() {
    super(...arguments);
    this._cached = null;
    this.nonstrict = this.passthrough;
    this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const shape = this._def.shape();
    const keys = util.objectKeys(shape);
    return this._cached = { shape, keys };
  }
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.object) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    const { status, ctx } = this._processInputParams(input);
    const { shape, keys: shapeKeys } = this._getCached();
    const extraKeys = [];
    if (!(this._def.catchall instanceof ZodNever && this._def.unknownKeys === "strip")) {
      for (const key in ctx.data) {
        if (!shapeKeys.includes(key)) {
          extraKeys.push(key);
        }
      }
    }
    const pairs = [];
    for (const key of shapeKeys) {
      const keyValidator = shape[key];
      const value = ctx.data[key];
      pairs.push({
        key: { status: "valid", value: key },
        value: keyValidator._parse(new ParseInputLazyPath(ctx, value, ctx.path, key)),
        alwaysSet: key in ctx.data
      });
    }
    if (this._def.catchall instanceof ZodNever) {
      const unknownKeys = this._def.unknownKeys;
      if (unknownKeys === "passthrough") {
        for (const key of extraKeys) {
          pairs.push({
            key: { status: "valid", value: key },
            value: { status: "valid", value: ctx.data[key] }
          });
        }
      } else if (unknownKeys === "strict") {
        if (extraKeys.length > 0) {
          addIssueToContext(ctx, {
            code: ZodIssueCode.unrecognized_keys,
            keys: extraKeys
          });
          status.dirty();
        }
      } else if (unknownKeys === "strip") ;
      else {
        throw new Error(`Internal ZodObject error: invalid unknownKeys value.`);
      }
    } else {
      const catchall = this._def.catchall;
      for (const key of extraKeys) {
        const value = ctx.data[key];
        pairs.push({
          key: { status: "valid", value: key },
          value: catchall._parse(
            new ParseInputLazyPath(ctx, value, ctx.path, key)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: key in ctx.data
        });
      }
    }
    if (ctx.common.async) {
      return Promise.resolve().then(async () => {
        const syncPairs = [];
        for (const pair of pairs) {
          const key = await pair.key;
          syncPairs.push({
            key,
            value: await pair.value,
            alwaysSet: pair.alwaysSet
          });
        }
        return syncPairs;
      }).then((syncPairs) => {
        return ParseStatus.mergeObjectSync(status, syncPairs);
      });
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get shape() {
    return this._def.shape();
  }
  strict(message) {
    errorUtil.errToObj;
    return new ZodObject({
      ...this._def,
      unknownKeys: "strict",
      ...message !== void 0 ? {
        errorMap: (issue, ctx) => {
          var _a, _b, _c, _d;
          const defaultError = (_c = (_b = (_a = this._def).errorMap) === null || _b === void 0 ? void 0 : _b.call(_a, issue, ctx).message) !== null && _c !== void 0 ? _c : ctx.defaultError;
          if (issue.code === "unrecognized_keys")
            return {
              message: (_d = errorUtil.errToObj(message).message) !== null && _d !== void 0 ? _d : defaultError
            };
          return {
            message: defaultError
          };
        }
      } : {}
    });
  }
  strip() {
    return new ZodObject({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new ZodObject({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(augmentation) {
    return new ZodObject({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...augmentation
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(merging) {
    const merged = new ZodObject({
      unknownKeys: merging._def.unknownKeys,
      catchall: merging._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...merging._def.shape()
      }),
      typeName: ZodFirstPartyTypeKind.ZodObject
    });
    return merged;
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(key, schema) {
    return this.augment({ [key]: schema });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(index2) {
    return new ZodObject({
      ...this._def,
      catchall: index2
    });
  }
  pick(mask) {
    const shape = {};
    util.objectKeys(mask).forEach((key) => {
      if (mask[key] && this.shape[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject({
      ...this._def,
      shape: () => shape
    });
  }
  omit(mask) {
    const shape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (!mask[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject({
      ...this._def,
      shape: () => shape
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return deepPartialify(this);
  }
  partial(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      const fieldSchema = this.shape[key];
      if (mask && !mask[key]) {
        newShape[key] = fieldSchema;
      } else {
        newShape[key] = fieldSchema.optional();
      }
    });
    return new ZodObject({
      ...this._def,
      shape: () => newShape
    });
  }
  required(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (mask && !mask[key]) {
        newShape[key] = this.shape[key];
      } else {
        const fieldSchema = this.shape[key];
        let newField = fieldSchema;
        while (newField instanceof ZodOptional) {
          newField = newField._def.innerType;
        }
        newShape[key] = newField;
      }
    });
    return new ZodObject({
      ...this._def,
      shape: () => newShape
    });
  }
  keyof() {
    return createZodEnum(util.objectKeys(this.shape));
  }
}
ZodObject.create = (shape, params) => {
  return new ZodObject({
    shape: () => shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
ZodObject.strictCreate = (shape, params) => {
  return new ZodObject({
    shape: () => shape,
    unknownKeys: "strict",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
ZodObject.lazycreate = (shape, params) => {
  return new ZodObject({
    shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
class ZodUnion extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const options = this._def.options;
    function handleResults(results) {
      for (const result of results) {
        if (result.result.status === "valid") {
          return result.result;
        }
      }
      for (const result of results) {
        if (result.result.status === "dirty") {
          ctx.common.issues.push(...result.ctx.common.issues);
          return result.result;
        }
      }
      const unionErrors = results.map((result) => new ZodError(result.ctx.common.issues));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return Promise.all(options.map(async (option) => {
        const childCtx = {
          ...ctx,
          common: {
            ...ctx.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await option._parseAsync({
            data: ctx.data,
            path: ctx.path,
            parent: childCtx
          }),
          ctx: childCtx
        };
      })).then(handleResults);
    } else {
      let dirty = void 0;
      const issues = [];
      for (const option of options) {
        const childCtx = {
          ...ctx,
          common: {
            ...ctx.common,
            issues: []
          },
          parent: null
        };
        const result = option._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: childCtx
        });
        if (result.status === "valid") {
          return result;
        } else if (result.status === "dirty" && !dirty) {
          dirty = { result, ctx: childCtx };
        }
        if (childCtx.common.issues.length) {
          issues.push(childCtx.common.issues);
        }
      }
      if (dirty) {
        ctx.common.issues.push(...dirty.ctx.common.issues);
        return dirty.result;
      }
      const unionErrors = issues.map((issues2) => new ZodError(issues2));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
  }
  get options() {
    return this._def.options;
  }
}
ZodUnion.create = (types, params) => {
  return new ZodUnion({
    options: types,
    typeName: ZodFirstPartyTypeKind.ZodUnion,
    ...processCreateParams(params)
  });
};
const getDiscriminator = (type) => {
  if (type instanceof ZodLazy) {
    return getDiscriminator(type.schema);
  } else if (type instanceof ZodEffects) {
    return getDiscriminator(type.innerType());
  } else if (type instanceof ZodLiteral) {
    return [type.value];
  } else if (type instanceof ZodEnum) {
    return type.options;
  } else if (type instanceof ZodNativeEnum) {
    return Object.keys(type.enum);
  } else if (type instanceof ZodDefault) {
    return getDiscriminator(type._def.innerType);
  } else if (type instanceof ZodUndefined) {
    return [void 0];
  } else if (type instanceof ZodNull) {
    return [null];
  } else {
    return null;
  }
};
class ZodDiscriminatedUnion extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const discriminator = this.discriminator;
    const discriminatorValue = ctx.data[discriminator];
    const option = this.optionsMap.get(discriminatorValue);
    if (!option) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Array.from(this.optionsMap.keys()),
        path: [discriminator]
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return option._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    } else {
      return option._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    }
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(discriminator, options, params) {
    const optionsMap = /* @__PURE__ */ new Map();
    for (const type of options) {
      const discriminatorValues = getDiscriminator(type.shape[discriminator]);
      if (!discriminatorValues) {
        throw new Error(`A discriminator value for key \`${discriminator}\` could not be extracted from all schema options`);
      }
      for (const value of discriminatorValues) {
        if (optionsMap.has(value)) {
          throw new Error(`Discriminator property ${String(discriminator)} has duplicate value ${String(value)}`);
        }
        optionsMap.set(value, type);
      }
    }
    return new ZodDiscriminatedUnion({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      discriminator,
      options,
      optionsMap,
      ...processCreateParams(params)
    });
  }
}
function mergeValues(a, b) {
  const aType = getParsedType(a);
  const bType = getParsedType(b);
  if (a === b) {
    return { valid: true, data: a };
  } else if (aType === ZodParsedType.object && bType === ZodParsedType.object) {
    const bKeys = util.objectKeys(b);
    const sharedKeys = util.objectKeys(a).filter((key) => bKeys.indexOf(key) !== -1);
    const newObj = { ...a, ...b };
    for (const key of sharedKeys) {
      const sharedValue = mergeValues(a[key], b[key]);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newObj[key] = sharedValue.data;
    }
    return { valid: true, data: newObj };
  } else if (aType === ZodParsedType.array && bType === ZodParsedType.array) {
    if (a.length !== b.length) {
      return { valid: false };
    }
    const newArray = [];
    for (let index2 = 0; index2 < a.length; index2++) {
      const itemA = a[index2];
      const itemB = b[index2];
      const sharedValue = mergeValues(itemA, itemB);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newArray.push(sharedValue.data);
    }
    return { valid: true, data: newArray };
  } else if (aType === ZodParsedType.date && bType === ZodParsedType.date && +a === +b) {
    return { valid: true, data: a };
  } else {
    return { valid: false };
  }
}
class ZodIntersection extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const handleParsed = (parsedLeft, parsedRight) => {
      if (isAborted(parsedLeft) || isAborted(parsedRight)) {
        return INVALID;
      }
      const merged = mergeValues(parsedLeft.value, parsedRight.value);
      if (!merged.valid) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.invalid_intersection_types
        });
        return INVALID;
      }
      if (isDirty(parsedLeft) || isDirty(parsedRight)) {
        status.dirty();
      }
      return { status: status.value, value: merged.data };
    };
    if (ctx.common.async) {
      return Promise.all([
        this._def.left._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        }),
        this._def.right._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        })
      ]).then(([left, right]) => handleParsed(left, right));
    } else {
      return handleParsed(this._def.left._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }), this._def.right._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }));
    }
  }
}
ZodIntersection.create = (left, right, params) => {
  return new ZodIntersection({
    left,
    right,
    typeName: ZodFirstPartyTypeKind.ZodIntersection,
    ...processCreateParams(params)
  });
};
class ZodTuple extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (ctx.data.length < this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_small,
        minimum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      return INVALID;
    }
    const rest = this._def.rest;
    if (!rest && ctx.data.length > this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_big,
        maximum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      status.dirty();
    }
    const items = [...ctx.data].map((item, itemIndex) => {
      const schema = this._def.items[itemIndex] || this._def.rest;
      if (!schema)
        return null;
      return schema._parse(new ParseInputLazyPath(ctx, item, ctx.path, itemIndex));
    }).filter((x) => !!x);
    if (ctx.common.async) {
      return Promise.all(items).then((results) => {
        return ParseStatus.mergeArray(status, results);
      });
    } else {
      return ParseStatus.mergeArray(status, items);
    }
  }
  get items() {
    return this._def.items;
  }
  rest(rest) {
    return new ZodTuple({
      ...this._def,
      rest
    });
  }
}
ZodTuple.create = (schemas, params) => {
  if (!Array.isArray(schemas)) {
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  }
  return new ZodTuple({
    items: schemas,
    typeName: ZodFirstPartyTypeKind.ZodTuple,
    rest: null,
    ...processCreateParams(params)
  });
};
class ZodRecord extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const pairs = [];
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    for (const key in ctx.data) {
      pairs.push({
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, key)),
        value: valueType._parse(new ParseInputLazyPath(ctx, ctx.data[key], ctx.path, key))
      });
    }
    if (ctx.common.async) {
      return ParseStatus.mergeObjectAsync(status, pairs);
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get element() {
    return this._def.valueType;
  }
  static create(first, second, third) {
    if (second instanceof ZodType) {
      return new ZodRecord({
        keyType: first,
        valueType: second,
        typeName: ZodFirstPartyTypeKind.ZodRecord,
        ...processCreateParams(third)
      });
    }
    return new ZodRecord({
      keyType: ZodString.create(),
      valueType: first,
      typeName: ZodFirstPartyTypeKind.ZodRecord,
      ...processCreateParams(second)
    });
  }
}
class ZodMap extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.map) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.map,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    const pairs = [...ctx.data.entries()].map(([key, value], index2) => {
      return {
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, [index2, "key"])),
        value: valueType._parse(new ParseInputLazyPath(ctx, value, ctx.path, [index2, "value"]))
      };
    });
    if (ctx.common.async) {
      const finalMap = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const pair of pairs) {
          const key = await pair.key;
          const value = await pair.value;
          if (key.status === "aborted" || value.status === "aborted") {
            return INVALID;
          }
          if (key.status === "dirty" || value.status === "dirty") {
            status.dirty();
          }
          finalMap.set(key.value, value.value);
        }
        return { status: status.value, value: finalMap };
      });
    } else {
      const finalMap = /* @__PURE__ */ new Map();
      for (const pair of pairs) {
        const key = pair.key;
        const value = pair.value;
        if (key.status === "aborted" || value.status === "aborted") {
          return INVALID;
        }
        if (key.status === "dirty" || value.status === "dirty") {
          status.dirty();
        }
        finalMap.set(key.value, value.value);
      }
      return { status: status.value, value: finalMap };
    }
  }
}
ZodMap.create = (keyType, valueType, params) => {
  return new ZodMap({
    valueType,
    keyType,
    typeName: ZodFirstPartyTypeKind.ZodMap,
    ...processCreateParams(params)
  });
};
class ZodSet extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.set) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.set,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const def = this._def;
    if (def.minSize !== null) {
      if (ctx.data.size < def.minSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.minSize.message
        });
        status.dirty();
      }
    }
    if (def.maxSize !== null) {
      if (ctx.data.size > def.maxSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.maxSize.message
        });
        status.dirty();
      }
    }
    const valueType = this._def.valueType;
    function finalizeSet(elements2) {
      const parsedSet = /* @__PURE__ */ new Set();
      for (const element of elements2) {
        if (element.status === "aborted")
          return INVALID;
        if (element.status === "dirty")
          status.dirty();
        parsedSet.add(element.value);
      }
      return { status: status.value, value: parsedSet };
    }
    const elements = [...ctx.data.values()].map((item, i) => valueType._parse(new ParseInputLazyPath(ctx, item, ctx.path, i)));
    if (ctx.common.async) {
      return Promise.all(elements).then((elements2) => finalizeSet(elements2));
    } else {
      return finalizeSet(elements);
    }
  }
  min(minSize, message) {
    return new ZodSet({
      ...this._def,
      minSize: { value: minSize, message: errorUtil.toString(message) }
    });
  }
  max(maxSize, message) {
    return new ZodSet({
      ...this._def,
      maxSize: { value: maxSize, message: errorUtil.toString(message) }
    });
  }
  size(size, message) {
    return this.min(size, message).max(size, message);
  }
  nonempty(message) {
    return this.min(1, message);
  }
}
ZodSet.create = (valueType, params) => {
  return new ZodSet({
    valueType,
    minSize: null,
    maxSize: null,
    typeName: ZodFirstPartyTypeKind.ZodSet,
    ...processCreateParams(params)
  });
};
class ZodFunction extends ZodType {
  constructor() {
    super(...arguments);
    this.validate = this.implement;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.function) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.function,
        received: ctx.parsedType
      });
      return INVALID;
    }
    function makeArgsIssue(args, error) {
      return makeIssue({
        data: args,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_arguments,
          argumentsError: error
        }
      });
    }
    function makeReturnsIssue(returns, error) {
      return makeIssue({
        data: returns,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_return_type,
          returnTypeError: error
        }
      });
    }
    const params = { errorMap: ctx.common.contextualErrorMap };
    const fn = ctx.data;
    if (this._def.returns instanceof ZodPromise) {
      return OK(async (...args) => {
        const error = new ZodError([]);
        const parsedArgs = await this._def.args.parseAsync(args, params).catch((e) => {
          error.addIssue(makeArgsIssue(args, e));
          throw error;
        });
        const result = await fn(...parsedArgs);
        const parsedReturns = await this._def.returns._def.type.parseAsync(result, params).catch((e) => {
          error.addIssue(makeReturnsIssue(result, e));
          throw error;
        });
        return parsedReturns;
      });
    } else {
      return OK((...args) => {
        const parsedArgs = this._def.args.safeParse(args, params);
        if (!parsedArgs.success) {
          throw new ZodError([makeArgsIssue(args, parsedArgs.error)]);
        }
        const result = fn(...parsedArgs.data);
        const parsedReturns = this._def.returns.safeParse(result, params);
        if (!parsedReturns.success) {
          throw new ZodError([makeReturnsIssue(result, parsedReturns.error)]);
        }
        return parsedReturns.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...items) {
    return new ZodFunction({
      ...this._def,
      args: ZodTuple.create(items).rest(ZodUnknown.create())
    });
  }
  returns(returnType) {
    return new ZodFunction({
      ...this._def,
      returns: returnType
    });
  }
  implement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  strictImplement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  static create(args, returns, params) {
    return new ZodFunction({
      args: args ? args : ZodTuple.create([]).rest(ZodUnknown.create()),
      returns: returns || ZodUnknown.create(),
      typeName: ZodFirstPartyTypeKind.ZodFunction,
      ...processCreateParams(params)
    });
  }
}
class ZodLazy extends ZodType {
  get schema() {
    return this._def.getter();
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const lazySchema = this._def.getter();
    return lazySchema._parse({ data: ctx.data, path: ctx.path, parent: ctx });
  }
}
ZodLazy.create = (getter, params) => {
  return new ZodLazy({
    getter,
    typeName: ZodFirstPartyTypeKind.ZodLazy,
    ...processCreateParams(params)
  });
};
class ZodLiteral extends ZodType {
  _parse(input) {
    if (input.data !== this._def.value) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_literal,
        expected: this._def.value
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
  get value() {
    return this._def.value;
  }
}
ZodLiteral.create = (value, params) => {
  return new ZodLiteral({
    value,
    typeName: ZodFirstPartyTypeKind.ZodLiteral,
    ...processCreateParams(params)
  });
};
function createZodEnum(values, params) {
  return new ZodEnum({
    values,
    typeName: ZodFirstPartyTypeKind.ZodEnum,
    ...processCreateParams(params)
  });
}
class ZodEnum extends ZodType {
  _parse(input) {
    if (typeof input.data !== "string") {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (this._def.values.indexOf(input.data) === -1) {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Values() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  extract(values) {
    return ZodEnum.create(values);
  }
  exclude(values) {
    return ZodEnum.create(this.options.filter((opt) => !values.includes(opt)));
  }
}
ZodEnum.create = createZodEnum;
class ZodNativeEnum extends ZodType {
  _parse(input) {
    const nativeEnumValues = util.getValidEnumValues(this._def.values);
    const ctx = this._getOrReturnCtx(input);
    if (ctx.parsedType !== ZodParsedType.string && ctx.parsedType !== ZodParsedType.number) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (nativeEnumValues.indexOf(input.data) === -1) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get enum() {
    return this._def.values;
  }
}
ZodNativeEnum.create = (values, params) => {
  return new ZodNativeEnum({
    values,
    typeName: ZodFirstPartyTypeKind.ZodNativeEnum,
    ...processCreateParams(params)
  });
};
class ZodPromise extends ZodType {
  unwrap() {
    return this._def.type;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.promise && ctx.common.async === false) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.promise,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const promisified = ctx.parsedType === ZodParsedType.promise ? ctx.data : Promise.resolve(ctx.data);
    return OK(promisified.then((data) => {
      return this._def.type.parseAsync(data, {
        path: ctx.path,
        errorMap: ctx.common.contextualErrorMap
      });
    }));
  }
}
ZodPromise.create = (schema, params) => {
  return new ZodPromise({
    type: schema,
    typeName: ZodFirstPartyTypeKind.ZodPromise,
    ...processCreateParams(params)
  });
};
class ZodEffects extends ZodType {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === ZodFirstPartyTypeKind.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const effect = this._def.effect || null;
    if (effect.type === "preprocess") {
      const processed = effect.transform(ctx.data);
      if (ctx.common.async) {
        return Promise.resolve(processed).then((processed2) => {
          return this._def.schema._parseAsync({
            data: processed2,
            path: ctx.path,
            parent: ctx
          });
        });
      } else {
        return this._def.schema._parseSync({
          data: processed,
          path: ctx.path,
          parent: ctx
        });
      }
    }
    const checkCtx = {
      addIssue: (arg) => {
        addIssueToContext(ctx, arg);
        if (arg.fatal) {
          status.abort();
        } else {
          status.dirty();
        }
      },
      get path() {
        return ctx.path;
      }
    };
    checkCtx.addIssue = checkCtx.addIssue.bind(checkCtx);
    if (effect.type === "refinement") {
      const executeRefinement = (acc) => {
        const result = effect.refinement(acc, checkCtx);
        if (ctx.common.async) {
          return Promise.resolve(result);
        }
        if (result instanceof Promise) {
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        return acc;
      };
      if (ctx.common.async === false) {
        const inner = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inner.status === "aborted")
          return INVALID;
        if (inner.status === "dirty")
          status.dirty();
        executeRefinement(inner.value);
        return { status: status.value, value: inner.value };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((inner) => {
          if (inner.status === "aborted")
            return INVALID;
          if (inner.status === "dirty")
            status.dirty();
          return executeRefinement(inner.value).then(() => {
            return { status: status.value, value: inner.value };
          });
        });
      }
    }
    if (effect.type === "transform") {
      if (ctx.common.async === false) {
        const base = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (!isValid(base))
          return base;
        const result = effect.transform(base.value, checkCtx);
        if (result instanceof Promise) {
          throw new Error(`Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.`);
        }
        return { status: status.value, value: result };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((base) => {
          if (!isValid(base))
            return base;
          return Promise.resolve(effect.transform(base.value, checkCtx)).then((result) => ({ status: status.value, value: result }));
        });
      }
    }
    util.assertNever(effect);
  }
}
ZodEffects.create = (schema, effect, params) => {
  return new ZodEffects({
    schema,
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    effect,
    ...processCreateParams(params)
  });
};
ZodEffects.createWithPreprocess = (preprocess, schema, params) => {
  return new ZodEffects({
    schema,
    effect: { type: "preprocess", transform: preprocess },
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    ...processCreateParams(params)
  });
};
class ZodOptional extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.undefined) {
      return OK(void 0);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ZodOptional.create = (type, params) => {
  return new ZodOptional({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodOptional,
    ...processCreateParams(params)
  });
};
class ZodNullable extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.null) {
      return OK(null);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ZodNullable.create = (type, params) => {
  return new ZodNullable({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodNullable,
    ...processCreateParams(params)
  });
};
class ZodDefault extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    let data = ctx.data;
    if (ctx.parsedType === ZodParsedType.undefined) {
      data = this._def.defaultValue();
    }
    return this._def.innerType._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
ZodDefault.create = (type, params) => {
  return new ZodDefault({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodDefault,
    defaultValue: typeof params.default === "function" ? params.default : () => params.default,
    ...processCreateParams(params)
  });
};
class ZodCatch extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const newCtx = {
      ...ctx,
      common: {
        ...ctx.common,
        issues: []
      }
    };
    const result = this._def.innerType._parse({
      data: newCtx.data,
      path: newCtx.path,
      parent: {
        ...newCtx
      }
    });
    if (isAsync(result)) {
      return result.then((result2) => {
        return {
          status: "valid",
          value: result2.status === "valid" ? result2.value : this._def.catchValue({
            get error() {
              return new ZodError(newCtx.common.issues);
            },
            input: newCtx.data
          })
        };
      });
    } else {
      return {
        status: "valid",
        value: result.status === "valid" ? result.value : this._def.catchValue({
          get error() {
            return new ZodError(newCtx.common.issues);
          },
          input: newCtx.data
        })
      };
    }
  }
  removeCatch() {
    return this._def.innerType;
  }
}
ZodCatch.create = (type, params) => {
  return new ZodCatch({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodCatch,
    catchValue: typeof params.catch === "function" ? params.catch : () => params.catch,
    ...processCreateParams(params)
  });
};
class ZodNaN extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.nan) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.nan,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
}
ZodNaN.create = (params) => {
  return new ZodNaN({
    typeName: ZodFirstPartyTypeKind.ZodNaN,
    ...processCreateParams(params)
  });
};
const BRAND = Symbol("zod_brand");
class ZodBranded extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const data = ctx.data;
    return this._def.type._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class ZodPipeline extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.common.async) {
      const handleAsync = async () => {
        const inResult = await this._def.in._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inResult.status === "aborted")
          return INVALID;
        if (inResult.status === "dirty") {
          status.dirty();
          return DIRTY(inResult.value);
        } else {
          return this._def.out._parseAsync({
            data: inResult.value,
            path: ctx.path,
            parent: ctx
          });
        }
      };
      return handleAsync();
    } else {
      const inResult = this._def.in._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
      if (inResult.status === "aborted")
        return INVALID;
      if (inResult.status === "dirty") {
        status.dirty();
        return {
          status: "dirty",
          value: inResult.value
        };
      } else {
        return this._def.out._parseSync({
          data: inResult.value,
          path: ctx.path,
          parent: ctx
        });
      }
    }
  }
  static create(a, b) {
    return new ZodPipeline({
      in: a,
      out: b,
      typeName: ZodFirstPartyTypeKind.ZodPipeline
    });
  }
}
const custom = (check, params = {}, fatal) => {
  if (check)
    return ZodAny.create().superRefine((data, ctx) => {
      var _a, _b;
      if (!check(data)) {
        const p = typeof params === "function" ? params(data) : typeof params === "string" ? { message: params } : params;
        const _fatal = (_b = (_a = p.fatal) !== null && _a !== void 0 ? _a : fatal) !== null && _b !== void 0 ? _b : true;
        const p2 = typeof p === "string" ? { message: p } : p;
        ctx.addIssue({ code: "custom", ...p2, fatal: _fatal });
      }
    });
  return ZodAny.create();
};
const late = {
  object: ZodObject.lazycreate
};
var ZodFirstPartyTypeKind;
(function(ZodFirstPartyTypeKind2) {
  ZodFirstPartyTypeKind2["ZodString"] = "ZodString";
  ZodFirstPartyTypeKind2["ZodNumber"] = "ZodNumber";
  ZodFirstPartyTypeKind2["ZodNaN"] = "ZodNaN";
  ZodFirstPartyTypeKind2["ZodBigInt"] = "ZodBigInt";
  ZodFirstPartyTypeKind2["ZodBoolean"] = "ZodBoolean";
  ZodFirstPartyTypeKind2["ZodDate"] = "ZodDate";
  ZodFirstPartyTypeKind2["ZodSymbol"] = "ZodSymbol";
  ZodFirstPartyTypeKind2["ZodUndefined"] = "ZodUndefined";
  ZodFirstPartyTypeKind2["ZodNull"] = "ZodNull";
  ZodFirstPartyTypeKind2["ZodAny"] = "ZodAny";
  ZodFirstPartyTypeKind2["ZodUnknown"] = "ZodUnknown";
  ZodFirstPartyTypeKind2["ZodNever"] = "ZodNever";
  ZodFirstPartyTypeKind2["ZodVoid"] = "ZodVoid";
  ZodFirstPartyTypeKind2["ZodArray"] = "ZodArray";
  ZodFirstPartyTypeKind2["ZodObject"] = "ZodObject";
  ZodFirstPartyTypeKind2["ZodUnion"] = "ZodUnion";
  ZodFirstPartyTypeKind2["ZodDiscriminatedUnion"] = "ZodDiscriminatedUnion";
  ZodFirstPartyTypeKind2["ZodIntersection"] = "ZodIntersection";
  ZodFirstPartyTypeKind2["ZodTuple"] = "ZodTuple";
  ZodFirstPartyTypeKind2["ZodRecord"] = "ZodRecord";
  ZodFirstPartyTypeKind2["ZodMap"] = "ZodMap";
  ZodFirstPartyTypeKind2["ZodSet"] = "ZodSet";
  ZodFirstPartyTypeKind2["ZodFunction"] = "ZodFunction";
  ZodFirstPartyTypeKind2["ZodLazy"] = "ZodLazy";
  ZodFirstPartyTypeKind2["ZodLiteral"] = "ZodLiteral";
  ZodFirstPartyTypeKind2["ZodEnum"] = "ZodEnum";
  ZodFirstPartyTypeKind2["ZodEffects"] = "ZodEffects";
  ZodFirstPartyTypeKind2["ZodNativeEnum"] = "ZodNativeEnum";
  ZodFirstPartyTypeKind2["ZodOptional"] = "ZodOptional";
  ZodFirstPartyTypeKind2["ZodNullable"] = "ZodNullable";
  ZodFirstPartyTypeKind2["ZodDefault"] = "ZodDefault";
  ZodFirstPartyTypeKind2["ZodCatch"] = "ZodCatch";
  ZodFirstPartyTypeKind2["ZodPromise"] = "ZodPromise";
  ZodFirstPartyTypeKind2["ZodBranded"] = "ZodBranded";
  ZodFirstPartyTypeKind2["ZodPipeline"] = "ZodPipeline";
})(ZodFirstPartyTypeKind || (ZodFirstPartyTypeKind = {}));
const instanceOfType = (cls, params = {
  message: `Input not instance of ${cls.name}`
}) => custom((data) => data instanceof cls, params);
const stringType = ZodString.create;
const numberType = ZodNumber.create;
const nanType = ZodNaN.create;
const bigIntType = ZodBigInt.create;
const booleanType = ZodBoolean.create;
const dateType = ZodDate.create;
const symbolType = ZodSymbol.create;
const undefinedType = ZodUndefined.create;
const nullType = ZodNull.create;
const anyType = ZodAny.create;
const unknownType = ZodUnknown.create;
const neverType = ZodNever.create;
const voidType = ZodVoid.create;
const arrayType = ZodArray.create;
const objectType = ZodObject.create;
const strictObjectType = ZodObject.strictCreate;
const unionType = ZodUnion.create;
const discriminatedUnionType = ZodDiscriminatedUnion.create;
const intersectionType = ZodIntersection.create;
const tupleType = ZodTuple.create;
const recordType = ZodRecord.create;
const mapType = ZodMap.create;
const setType = ZodSet.create;
const functionType = ZodFunction.create;
const lazyType = ZodLazy.create;
const literalType = ZodLiteral.create;
const enumType = ZodEnum.create;
const nativeEnumType = ZodNativeEnum.create;
const promiseType = ZodPromise.create;
const effectsType = ZodEffects.create;
const optionalType = ZodOptional.create;
const nullableType = ZodNullable.create;
const preprocessType = ZodEffects.createWithPreprocess;
const pipelineType = ZodPipeline.create;
const ostring = () => stringType().optional();
const onumber = () => numberType().optional();
const oboolean = () => booleanType().optional();
const coerce = {
  string: (arg) => ZodString.create({ ...arg, coerce: true }),
  number: (arg) => ZodNumber.create({ ...arg, coerce: true }),
  boolean: (arg) => ZodBoolean.create({
    ...arg,
    coerce: true
  }),
  bigint: (arg) => ZodBigInt.create({ ...arg, coerce: true }),
  date: (arg) => ZodDate.create({ ...arg, coerce: true })
};
const NEVER = INVALID;
var z = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: errorMap,
  setErrorMap,
  getErrorMap,
  makeIssue,
  EMPTY_PATH,
  addIssueToContext,
  ParseStatus,
  INVALID,
  DIRTY,
  OK,
  isAborted,
  isDirty,
  isValid,
  isAsync,
  get util() {
    return util;
  },
  get objectUtil() {
    return objectUtil;
  },
  ZodParsedType,
  getParsedType,
  ZodType,
  ZodString,
  ZodNumber,
  ZodBigInt,
  ZodBoolean,
  ZodDate,
  ZodSymbol,
  ZodUndefined,
  ZodNull,
  ZodAny,
  ZodUnknown,
  ZodNever,
  ZodVoid,
  ZodArray,
  ZodObject,
  ZodUnion,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodTuple,
  ZodRecord,
  ZodMap,
  ZodSet,
  ZodFunction,
  ZodLazy,
  ZodLiteral,
  ZodEnum,
  ZodNativeEnum,
  ZodPromise,
  ZodEffects,
  ZodTransformer: ZodEffects,
  ZodOptional,
  ZodNullable,
  ZodDefault,
  ZodCatch,
  ZodNaN,
  BRAND,
  ZodBranded,
  ZodPipeline,
  custom,
  Schema: ZodType,
  ZodSchema: ZodType,
  late,
  get ZodFirstPartyTypeKind() {
    return ZodFirstPartyTypeKind;
  },
  coerce,
  any: anyType,
  array: arrayType,
  bigint: bigIntType,
  boolean: booleanType,
  date: dateType,
  discriminatedUnion: discriminatedUnionType,
  effect: effectsType,
  "enum": enumType,
  "function": functionType,
  "instanceof": instanceOfType,
  intersection: intersectionType,
  lazy: lazyType,
  literal: literalType,
  map: mapType,
  nan: nanType,
  nativeEnum: nativeEnumType,
  never: neverType,
  "null": nullType,
  nullable: nullableType,
  number: numberType,
  object: objectType,
  oboolean,
  onumber,
  optional: optionalType,
  ostring,
  pipeline: pipelineType,
  preprocess: preprocessType,
  promise: promiseType,
  record: recordType,
  set: setType,
  strictObject: strictObjectType,
  string: stringType,
  symbol: symbolType,
  transformer: effectsType,
  tuple: tupleType,
  "undefined": undefinedType,
  union: unionType,
  unknown: unknownType,
  "void": voidType,
  NEVER,
  ZodIssueCode,
  quotelessJson,
  ZodError
});
var EventStatus = /* @__PURE__ */ ((EventStatus2) => {
  EventStatus2["DRAFT"] = "DRAFT";
  EventStatus2["FINISHED"] = "FINISHED";
  EventStatus2["ON_AIR"] = "ON_AIR";
  EventStatus2["PLANNED"] = "PLANNED";
  EventStatus2["PRIVATE_PREVIEW"] = "PRIVATE_PREVIEW";
  EventStatus2["PRIVATE_REPLAY"] = "PRIVATE_REPLAY";
  EventStatus2["REPLAY"] = "REPLAY";
  EventStatus2["REPLAY_READY"] = "REPLAY_READY";
  EventStatus2["REPLAY_FAILED"] = "REPLAY_FAILED";
  return EventStatus2;
})(EventStatus || {});
const UNFINISHED_EVENT_STATUSES = [
  "DRAFT",
  "PLANNED",
  "ON_AIR",
  "PRIVATE_PREVIEW"
  /* PRIVATE_PREVIEW */
];
const HAS_BEEN_LIVE_EVENT_STATUSES = [
  "FINISHED",
  "ON_AIR",
  "PRIVATE_REPLAY",
  "REPLAY",
  "REPLAY_FAILED",
  "REPLAY_READY"
  /* REPLAY_READY */
];
const TEST_OR_LATER_EVENT_STATUSES = [
  "FINISHED",
  "ON_AIR",
  "PRIVATE_PREVIEW",
  "PRIVATE_REPLAY",
  "REPLAY_FAILED",
  "REPLAY_READY",
  "REPLAY"
  /* REPLAY */
];
const FROZEN_EVENT_STATUSES = TEST_OR_LATER_EVENT_STATUSES;
const FINISHED_OR_LATER_EVENT_STATUSES = [
  "FINISHED",
  "PRIVATE_REPLAY",
  "REPLAY",
  "REPLAY_FAILED",
  "REPLAY_READY"
  /* REPLAY_READY */
];
const PUBLISHABLE_REPLAY_EVENT_STATUSES = [
  "FINISHED",
  "PRIVATE_REPLAY",
  "REPLAY_FAILED",
  "REPLAY_READY"
  /* REPLAY_READY */
];
const REPLAY_STATUSES = [
  "PRIVATE_REPLAY",
  "REPLAY",
  "REPLAY_FAILED",
  "REPLAY_READY"
  /* REPLAY_READY */
];
const HIGHLIGHTABLE_STATUSES = [
  "PLANNED",
  "ON_AIR",
  "PRIVATE_PREVIEW"
  /* PRIVATE_PREVIEW */
];
const StringSchema = z.string().trim();
const StringNonEmptySchema = z.string().trim().nonempty();
const StringNullableSchema = StringSchema.nullable();
const URLSchema = z.string().trim().url();
const URLNullableSchema = URLSchema.nullable();
const UUIDSchema = StringSchema.uuid();
const UUIDNullableSchema = UUIDSchema.nullable();
const SafeFloatSchema = z.number().finite().safe();
const SafeIntegerSchema = SafeFloatSchema.int();
const SafeNonNegativeIntegerSchema = SafeIntegerSchema.nonnegative();
const SafeNonNegativeFloatSchema = SafeFloatSchema.nonnegative();
const InventoryPolicySchema = z.union([z.literal("deny"), z.literal("continue"), z.null()]);
const ProductOptionValueSchema = z.object({
  id: StringSchema,
  name: StringSchema
});
const ProductVariantSchema = z.object({
  coverUrl: URLNullableSchema,
  crossedOutPrice: z.number().nullable(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema
});
const ProductOptionSchema = z.object({
  allValues: ProductOptionValueSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: ProductOptionValueSchema.array(),
  type: StringSchema
});
const ProductOptionsSchema = z.object({
  option1: ProductOptionSchema,
  option2: ProductOptionSchema.nullable(),
  option3: ProductOptionSchema.nullable(),
  variantsHaveSamePrice: z.boolean(),
  variants: ProductVariantSchema.array()
});
const ProductDTOSchema$1 = z.object({
  createdAt: StringSchema.datetime(),
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: StringSchema.array(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional().nullable(),
  options: ProductOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema
});
ProductDTOSchema$1.extend({
  isSynchronizedAutomatically: z.boolean(),
  originalId: UUIDSchema,
  status: z.string(),
  deletedAt: StringSchema.datetime().nullable()
  // TODO remove this type CatalogProduct and use only the Entity and its state
});
const RestreamingPlatformSchema = z.enum([
  "Facebook",
  "Facebook_RTMP",
  "Instagram",
  "Other_RTMP_Source",
  "Youtube",
  "Youtube_RTMP"
]);
const RestreamingConfigurationDTOSchema = z.discriminatedUnion("enabled", [
  z.object({
    enabled: z.literal(true),
    expiresAt: z.string().datetime().nullable(),
    key: StringNonEmptySchema,
    type: RestreamingPlatformSchema,
    url: URLSchema
  }),
  z.object({
    enabled: z.literal(false),
    expiresAt: z.null(),
    key: StringSchema,
    type: RestreamingPlatformSchema,
    url: StringSchema
  })
]);
const FacebookRestreamingAccountType = ["PAGE", "USER"];
const FacebookRestreamingAccountTypeSchema = z.enum(FacebookRestreamingAccountType);
const FacebookRestreamingConfigurationDTOSchema = z.object({
  accessToken: StringSchema,
  expiresAt: z.string().datetime(),
  id: StringSchema,
  name: StringSchema,
  picture: StringSchema,
  type: FacebookRestreamingAccountTypeSchema
});
const YoutubeRestreamingConfigurationDTOSchema = z.object({
  accessToken: StringSchema,
  expiresAt: z.string().datetime(),
  id: StringSchema,
  picture: StringSchema,
  refreshToken: StringSchema,
  title: StringSchema
});
const constraints$8 = {
  description: {
    minLength: 5,
    maxLength: 2e3
  },
  title: {
    minLength: 3,
    maxLength: 60
  }
};
const CreateProductDTOVariantSchema = z.object({
  coverUrl: URLNullableSchema,
  crossedOutPrice: z.number().nullable(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema
});
const CreateProductDTOOptionValueSchema = z.object({
  id: UUIDSchema,
  name: StringSchema
});
const CreateProductDTOOptionSchema = z.object({
  allValues: CreateProductDTOOptionValueSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: CreateProductDTOOptionValueSchema.array(),
  type: StringSchema
});
const CreateProductDTOOptionsSchema = z.object({
  option1: CreateProductDTOOptionSchema,
  option2: CreateProductDTOOptionSchema.nullable(),
  option3: CreateProductDTOOptionSchema.nullable(),
  variants: CreateProductDTOVariantSchema.array(),
  variantsHaveSamePrice: z.boolean()
});
const CreateProductDTOSchema = z.object({
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: URLSchema.array(),
  options: CreateProductDTOOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema
});
const DisplayProductDTOSchema = z.object({
  productId: UUIDSchema
});
const ProductDTOSchema = z.object({
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: StringSchema.array(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional().nullable(),
  options: ProductOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema
});
const ReorderProductsLineupDTOSchema = z.object({
  newProductsOrder: UUIDSchema.array()
});
const UpdateProductDTOSchema = ProductDTOSchema.omit({ createdAt: true, id: true });
const AuthenticateChatGuestDTOSchema = z.object({
  authToken: StringSchema
});
const ChatCredentialsDTOSchema = z.object({
  authenticationToken: StringSchema,
  userId: StringSchema
});
const PinMessageDTOSchema = z.object({
  content: StringSchema,
  messageId: StringSchema
});
const PinnedMessageDTOSchema = z.object({
  content: StringSchema,
  id: UUIDSchema,
  messageId: StringSchema,
  pinnedAt: StringSchema,
  unpinnedAt: StringNullableSchema
});
const UnpinMessageDTOSchema = z.object({
  messageId: StringSchema
});
const EventThemeDTOSchema = z.object({
  addToCartBackgroundColor: StringSchema,
  adminMessageBackgroundColor: StringSchema,
  adminMessageIconUrl: URLSchema,
  brandLogoUrl: URLSchema,
  cartIconUrl: URLSchema,
  mainFontName: StringSchema,
  postEventCoverUrl: URLSchema,
  preEventCoverUrl: URLSchema,
  removeFromCartBackgroundColor: StringSchema,
  storeIconUrl: URLSchema,
  validateCartBackgroundColor: StringSchema
});
const CustomiseEventThemeDTOSchema = EventThemeDTOSchema.omit({
  adminMessageIconUrl: true,
  brandLogoUrl: true,
  cartIconUrl: true,
  postEventCoverUrl: true,
  preEventCoverUrl: true,
  storeIconUrl: true
}).extend({
  adminMessageIconUploaded: z.boolean(),
  brandLogoUploaded: z.boolean(),
  cartIconUploaded: z.boolean(),
  postEventCoverUploaded: z.boolean(),
  preEventCoverUploaded: z.boolean(),
  storeIconUploaded: z.boolean()
});
const ThemeFileUploadUrlsDTOSchema = z.object({
  adminMessageIconUrl: URLSchema,
  brandLogoUrl: URLSchema,
  cartIconUrl: URLSchema,
  postEventCoverUrl: URLSchema,
  preEventCoverUrl: URLSchema,
  storeIconUrl: URLSchema
});
const constraints$7 = {
  chatIntroductionText: {
    minLength: 0,
    maxLength: 2e3
  },
  description: {
    minLength: 5,
    maxLength: 2e3
  },
  moderatorMessageLabel: {
    minLength: 0,
    maxLength: 20
  },
  preEventText: {
    minLength: 0,
    maxLength: 1e3
  },
  postEventText: {
    minLength: 0,
    maxLength: 1e3
  },
  rtmpRestreamingKey: {
    minLength: 0,
    maxLength: 255
  },
  rtmpRestreamingUrl: {
    minLength: 0,
    maxLength: 2e3
  },
  title: {
    minLength: 3,
    maxLength: 100
  }
};
const StreamKeyDTOSchema = z.object({
  key: StringSchema,
  url: StringSchema
});
const VideoStreamChannelDTOSchema = z.object({
  channelId: StringSchema,
  streamKey: StreamKeyDTOSchema
});
const AddCatalogProductsToEventDTOSchema = z.object({
  productsIds: UUIDSchema.array()
});
const CurrencySchema = z.object({
  code: StringSchema,
  decimalDigits: z.number(),
  id: UUIDSchema,
  symbol: StringSchema
});
const CreateEventDTOSchema = z.object({
  description: StringSchema,
  id: UUIDSchema,
  publisherPhone: StringSchema,
  showTime: StringNullableSchema,
  tenantId: UUIDSchema.optional(),
  title: StringSchema
});
z.object({
  authenticationToken: StringSchema,
  userId: StringSchema
});
const EventDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  chatModeratorIds: StringSchema.array(),
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  finishTime: StringSchema.datetime().nullable(),
  id: UUIDSchema,
  isLiveViewersCountDisplayed: z.boolean(),
  moderatorMessageLabel: StringSchema,
  pinnedChatMessage: PinnedMessageDTOSchema.nullable(),
  postEventText: StringSchema,
  preEventText: StringSchema,
  products: ProductDTOSchema.array(),
  publisherPhone: StringSchema,
  replayFileUrl: URLNullableSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime().nullable(),
  status: z.nativeEnum(EventStatus),
  tenantId: UUIDSchema,
  theme: EventThemeDTOSchema,
  title: StringSchema,
  videoStreamChannel: VideoStreamChannelDTOSchema,
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable()
});
const EventDepictionDTOSchema = EventDTOSchema.pick({
  id: true,
  title: true
}).extend({
  image: URLSchema,
  showTime: StringSchema.datetime().nullable()
});
const PublicEventDepictionDTOSchema = EventDTOSchema.pick({
  id: true,
  title: true,
  description: true,
  status: true,
  showTime: true
}).extend({
  preEventCoverUrl: URLSchema
});
const ReplayFileUploadUrlsDTOSchema = z.object({
  coverUrl: StringSchema
});
const ToggleLiveViewersCountDisplayDTOSchema = z.object({
  isDisplayed: z.boolean()
});
const UpdateEventDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  moderatorMessageLabel: StringSchema,
  postEventText: StringSchema,
  preEventText: StringSchema,
  publisherPhone: StringSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringNullableSchema,
  title: StringSchema,
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable()
});
const UpdateReplayDTOSchema = z.object({
  coverUploaded: z.boolean(),
  description: StringSchema,
  title: StringSchema
});
const UpdateEventViewersCountDTOSchema = z.object({
  eventId: UUIDSchema
});
const ViewerTokenDTOSchema = z.object({
  channelId: StringSchema,
  viewerToken: StringSchema
});
const ReplayChapterDTOSchema = z.object({
  endAt: z.number(),
  image: URLSchema,
  startAt: z.number(),
  title: StringSchema
});
const ReplaysChaptersDTOSchema = z.record(UUIDSchema, ReplayChapterDTOSchema.array());
const constraints$6 = {
  replaySectionTitle: {
    minLength: 0,
    maxLength: 100
  }
};
const HasCmsLandingPageDTOSchema$1 = z.object({
  hasCmsLandingPage: z.boolean()
});
const LandingPageDTOSchema$1 = z.object({
  highlightedEventId: UUIDNullableSchema,
  landingPageUrl: z.string(),
  publishedReplayEventsIds: z.array(z.string()),
  replaySectionTitle: z.string()
});
const UpdateLandingPageDTOSchema$1 = LandingPageDTOSchema$1.omit({
  landingPageUrl: true
});
const LandingPageWithPublicEventDepictionsDTOSchema$1 = z.object({
  askEmail: z.boolean(),
  highlightedEvent: PublicEventDepictionDTOSchema.nullable(),
  publishedReplayEvents: PublicEventDepictionDTOSchema.array(),
  replaySectionTitle: StringSchema
});
const LandingPageIdSchema$1 = UUIDSchema.brand("LandingPageId");
const constraints$5 = {
  maxMediasCount: 6,
  title: {
    minLength: 3,
    maxLength: 100
  }
};
const MediaCollectionReplayMediaPublicDTOSchema = z.object({
  id: UUIDSchema,
  type: z.literal("replay")
});
const MediaCollectionReplayChapterMediaPublicDTOSchema = z.object({
  endTimeStamp: z.number(),
  id: UUIDSchema,
  startTimeStamp: z.number(),
  type: z.literal("replay-chapter")
});
const MediaCollectionShoppableMediaPublicDTOSchema = z.object({
  id: UUIDSchema,
  type: z.literal("shoppable")
});
const MediaCollectionMediaPublicDTOSchema = z.union([
  MediaCollectionShoppableMediaPublicDTOSchema,
  MediaCollectionReplayMediaPublicDTOSchema,
  MediaCollectionReplayChapterMediaPublicDTOSchema
]);
const MediaCollectionPublishedCollectionPagePublicDTOSchema = z.object({
  type: z.literal("collection"),
  id: StringSchema
});
const MediaCollectionPublishedHomePagePublicDTOSchema = z.object({
  type: z.literal("home")
});
const MediaCollectionPublishedProductPagePublicDTOSchema = z.object({
  id: StringSchema,
  type: z.literal("product")
});
const MediaCollectionPublishedPagePagePublicDTOSchema = z.object({
  id: StringSchema,
  type: z.literal("page")
});
const MediaCollectionPublishedPagePublicDTOSchema = z.union([
  MediaCollectionPublishedHomePagePublicDTOSchema,
  MediaCollectionPublishedProductPagePublicDTOSchema,
  MediaCollectionPublishedCollectionPagePublicDTOSchema,
  MediaCollectionPublishedPagePagePublicDTOSchema
]);
const MediaCollectionPublicDTOSchema = z.object({
  id: UUIDSchema,
  isInfiniteMediaCollectionEnabled: z.boolean(),
  medias: MediaCollectionMediaPublicDTOSchema.array(),
  publishedPages: MediaCollectionPublishedPagePublicDTOSchema.array(),
  tenantId: UUIDSchema,
  title: StringSchema
});
const GetOneMediaCollectionPublicDTOSchema = z.object({
  mediaCollection: MediaCollectionPublicDTOSchema,
  otherPublishedMediaCollectionIds: UUIDSchema.array()
});
const MediaCollectionMediaBaseDTOSchema = z.object({
  image: URLNullableSchema,
  title: StringSchema
});
const MediaCollectionReplayChapterMediaDTOSchema = MediaCollectionReplayChapterMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema
);
const MediaCollectionReplayMediaDTOSchema = MediaCollectionReplayMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema
);
const MediaCollectionShoppableMediaDTOSchema = MediaCollectionShoppableMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema
).extend({
  duration: SafeNonNegativeFloatSchema.nullable(),
  productId: UUIDSchema,
  productOriginalId: StringSchema,
  productImage: URLNullableSchema,
  productTitle: StringSchema
});
const MediaCollectionMediaDTOSchema = z.union([
  MediaCollectionShoppableMediaDTOSchema,
  MediaCollectionReplayMediaDTOSchema,
  MediaCollectionReplayChapterMediaDTOSchema
]);
const MediaCollectionPublishedCollectionPageDTOSchema = MediaCollectionPublishedCollectionPagePublicDTOSchema;
const MediaCollectionPublishedPagePageDTOSchema = MediaCollectionPublishedPagePagePublicDTOSchema;
const MediaCollectionPublishedHomePageDTOSchema = MediaCollectionPublishedHomePagePublicDTOSchema;
const MediaCollectionPublishedProductPageDTOSchema = MediaCollectionPublishedProductPagePublicDTOSchema.extend({
  image: URLNullableSchema,
  title: StringSchema
});
const MediaCollectionPublishedPageDTOSchema = z.union([
  MediaCollectionPublishedHomePageDTOSchema,
  MediaCollectionPublishedProductPageDTOSchema,
  MediaCollectionPublishedCollectionPageDTOSchema,
  MediaCollectionPublishedPagePageDTOSchema
]);
MediaCollectionPublicDTOSchema.extend({
  medias: MediaCollectionMediaDTOSchema.array(),
  publishedPages: MediaCollectionPublishedPageDTOSchema.array()
});
const CreateMediaCollectionDTOSchema = z.object({
  id: UUIDSchema,
  isInfiniteMediaCollectionEnabled: z.boolean(),
  medias: MediaCollectionMediaPublicDTOSchema.array(),
  title: StringSchema
});
const UpdateMediaCollectionPagesDTOSchema = z.object({
  publishedPages: MediaCollectionPublishedPagePublicDTOSchema.array()
});
const UpdateMediaCollectionSettingsDTOSChema = CreateMediaCollectionDTOSchema.omit({ id: true });
const constraints$4 = {
  title: {
    minLength: 1,
    maxLength: 30
  }
};
const CreateShoppableVideoDTOSchema = z.object({
  id: UUIDSchema,
  productId: UUIDSchema,
  title: StringNonEmptySchema.min(constraints$4.title.minLength).max(constraints$4.title.maxLength)
});
const GetVideoFromSocialMediaUrlDTOSchema = z.object({
  url: URLSchema
});
const GetVideoFromSocialMediaUrlResponseDTOSchema = z.object({
  videoUrl: URLSchema
});
const ShoppableVideoStatus = [
  "DRAFT",
  "OPTIMIZATION_FAILED",
  "OPTIMIZING",
  "PUBLISHED",
  "PUBLISHING",
  "READY",
  "UNPUBLISHING"
];
const ShoppableVideoStatusSchema = z.enum(ShoppableVideoStatus);
const ShoppableVideoDTOSchema = z.object({
  createdAt: z.string().datetime(),
  id: UUIDSchema,
  productId: UUIDSchema,
  status: ShoppableVideoStatusSchema,
  tenantId: UUIDSchema,
  thumbnailUrl: URLSchema.nullable(),
  title: StringNonEmptySchema,
  videoDuration: z.number().nullable(),
  videoUrl: URLNullableSchema
});
const ShoppableVideoThumbnailFileUploadUrlDTOSchema = z.object({
  url: URLSchema
});
const ShoppableVideoFileUploadUrlDTOSchema = z.object({
  url: URLSchema
});
const ShoppableVideoFileUploadCompletedDTOSchema = z.object({
  fileUrl: URLSchema
});
const ShoppableThemeFileUploadUrlsDTOSchema = z.object({
  brandLogoUrl: URLSchema
});
const ShoppablesSettingsDTOSchema = z.object({
  theme: z.object({
    addToCartBackgroundColor: StringSchema,
    brandLogoUrl: URLSchema,
    mainFontName: StringSchema
  })
});
const UpdateShoppableVideoDTOSchema = z.object({
  title: StringNonEmptySchema,
  thumbnailUploaded: z.boolean()
});
const UpdateShoppablesSettingsDTOSchema = z.object({
  theme: z.object({
    addToCartBackgroundColor: StringSchema,
    brandLogoUploaded: z.boolean(),
    mainFontName: StringSchema
  })
});
const Cms = {
  "Custom": "CUSTOM",
  "PrestaShop1.6": "PRESTASHOP1.6",
  "PrestaShop1.7": "PRESTASHOP1.7",
  "PrestaShop8": "PRESTASHOP8",
  "SFCC": "SFCC",
  "Shopify": "SHOPIFY",
  "Unknown": "UNKNOWN",
  "WooCommerce": "WOOCOMMERCE"
};
const CmsSchema = z.nativeEnum(Cms);
const CMS_WITH_SALES_TRACKING = [Cms.Shopify];
const CurrencyDTOSchema = z.object({
  code: StringSchema,
  decimalDigits: SafeNonNegativeIntegerSchema,
  id: UUIDSchema,
  symbol: StringSchema
});
const UpdateCurrencyDTOSchema = z.object({
  currencyId: UUIDSchema
});
const TWENTY_MINUTES = 20 * 60;
const ONE_HOUR = 60 * 60;
const THREE_HOURS = 3 * 60 * 60;
const BILLING_PLANS = {
  live_access: {
    canEnableInfiniteMediaCollection: false,
    canEnableMultistream: false,
    canHighlightMultipleEvents: false,
    canPublishOnHomePage: false,
    canPublishOnPagePages: false,
    canSelectReplaysOnMediaCollection: false,
    id: "live_access",
    integrationsEnabled: {
      klaviyo: false,
      napps: false,
      tapcart: false
    },
    maxPublishedReplayDurationInSeconds: 22 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 10,
    isLMULogoDisplayed: true,
    maxLiveDurationInSeconds: TWENTY_MINUTES,
    monthlyPrice: 0,
    name: "Start",
    obsEnabled: false,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_engage: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: false,
    canPublishOnHomePage: true,
    canPublishOnPagePages: false,
    canSelectReplaysOnMediaCollection: true,
    id: "live_engage",
    integrationsEnabled: {
      klaviyo: false,
      napps: true,
      tapcart: false
    },
    maxPublishedReplayDurationInSeconds: 65 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 50,
    isLMULogoDisplayed: true,
    maxLiveDurationInSeconds: ONE_HOUR,
    monthlyPrice: 89,
    name: "Grow",
    obsEnabled: false,
    streamingResolution: "hd",
    trialDays: 14
  },
  shoppable_engage: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: false,
    canPublishOnHomePage: true,
    canPublishOnPagePages: false,
    canSelectReplaysOnMediaCollection: true,
    id: "shoppable_engage",
    integrationsEnabled: {
      klaviyo: false,
      napps: false,
      tapcart: false
    },
    isLMULogoDisplayed: true,
    maxLiveDurationInSeconds: TWENTY_MINUTES,
    maxPublishedReplayDurationInSeconds: 22 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 150,
    monthlyPrice: 89,
    name: "Grow - Shoppable only",
    obsEnabled: false,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_power: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_power",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: true,
    maxLiveDurationInSeconds: THREE_HOURS,
    maxPublishedShoppables: 150,
    name: "Scale",
    monthlyPrice: 249,
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  shoppable_power: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: false,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "shoppable_power",
    integrationsEnabled: {
      klaviyo: false,
      napps: false,
      tapcart: false
    },
    isLMULogoDisplayed: true,
    maxPublishedReplayDurationInSeconds: 22 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 400,
    name: "Scale - Shoppable only",
    maxLiveDurationInSeconds: TWENTY_MINUTES,
    monthlyPrice: 249,
    obsEnabled: false,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_1_hd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_hd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_1_fhd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_fhd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_2_hd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_hd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_2_fhd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_fhd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_3_hd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_hd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_3_fhd_us_eu: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_fhd_us_eu",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_1_hd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_hd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_1_fhd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_fhd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_2_hd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_hd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_2_fhd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_fhd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_3_hd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_hd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_3_fhd_sa_as: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_fhd_sa_as",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_1_hd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_hd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_1_fhd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_1_fhd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 399,
    name: "Enterprise 1 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_2_hd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_hd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_2_fhd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_2_fhd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 599,
    name: "Enterprise 2 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  },
  live_enterprise_3_hd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_hd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 HD",
    obsEnabled: true,
    streamingResolution: "hd",
    trialDays: 14
  },
  live_enterprise_3_fhd_cn_au: {
    canEnableInfiniteMediaCollection: true,
    canEnableMultistream: true,
    canHighlightMultipleEvents: true,
    canPublishOnHomePage: true,
    canPublishOnPagePages: true,
    canSelectReplaysOnMediaCollection: true,
    id: "live_enterprise_3_fhd_cn_au",
    integrationsEnabled: {
      klaviyo: true,
      napps: true,
      tapcart: true
    },
    isLMULogoDisplayed: false,
    maxLiveDurationInSeconds: null,
    monthlyPrice: 799,
    name: "Enterprise 3 FHD",
    obsEnabled: true,
    streamingResolution: "fhd",
    trialDays: 14
  }
};
const BillingPlanIdSchema = z.enum(Object.keys(BILLING_PLANS));
const DateLikeSchema = z.union([z.number(), z.string(), z.date()]);
const DateLikeOrNullSchema = z.union([z.number(), z.null(), z.string(), z.date()]);
const CoerceNotNullDateSchema = DateLikeSchema.pipe(z.coerce.date());
const CoerceNullableDateSchema = DateLikeOrNullSchema.pipe(z.coerce.date()).nullable();
const ISO31661Alpha2Schema = z.string().trim().toUpperCase().regex(/^[A-Z][A-Z]$/);
const constraints$3 = {
  alias: {
    minLength: 3,
    maxLength: 200
  }
};
const CreateTenantDTOSchema = z.object({
  countryCode: ISO31661Alpha2Schema,
  cms: CmsSchema,
  currencyId: UUIDSchema,
  masterEmail: StringSchema.email(),
  tenantName: StringSchema
});
const KlaviyoIntegrationDTOSchema = z.object({
  apiKey: StringNullableSchema,
  listId: StringNullableSchema
});
const TapcartIntegrationDTOSchema = z.object({
  screenId: StringNullableSchema
});
const IntegrationsDTOSchema = z.object({
  klaviyo: KlaviyoIntegrationDTOSchema,
  tapcart: TapcartIntegrationDTOSchema
});
const UpdateIntegrationsDTOSchema = IntegrationsDTOSchema;
const TenantDTOSchema = z.object({
  activeSubscriptionId: BillingPlanIdSchema.nullable(),
  alias: StringSchema,
  apiKey: UUIDSchema,
  cms: CmsSchema,
  createdAt: StringSchema.datetime(),
  currency: CurrencyDTOSchema,
  hasCatalogProducts: z.boolean(),
  id: UUIDSchema,
  integrations: IntegrationsDTOSchema,
  name: StringSchema
});
const LiveReplayCountsDTOSchema = z.object({
  live: SafeNonNegativeFloatSchema,
  replay: SafeNonNegativeFloatSchema
});
const LiveReplayIntegerCountsDTOSchema = z.object({
  live: SafeNonNegativeIntegerSchema,
  replay: SafeNonNegativeIntegerSchema
});
const ECommerceStatisticsDTOSchema = z.object({
  addedProducts: LiveReplayIntegerCountsDTOSchema,
  cartsAmounts: LiveReplayCountsDTOSchema.nullable(),
  filledCarts: LiveReplayIntegerCountsDTOSchema,
  validatedCarts: LiveReplayIntegerCountsDTOSchema,
  validatedCartsAmounts: LiveReplayCountsDTOSchema.nullable(),
  validatedProducts: LiveReplayIntegerCountsDTOSchema
});
const ECommerceStatisticsWithSalesDTOSchema = ECommerceStatisticsDTOSchema.extend({
  purchasedAmounts: LiveReplayCountsDTOSchema,
  purchasedCarts: LiveReplayIntegerCountsDTOSchema
});
const EngagementStatisticsDTOSchema = z.object({
  chatMessages: SafeNonNegativeIntegerSchema,
  chatUsers: SafeNonNegativeIntegerSchema,
  likes: LiveReplayIntegerCountsDTOSchema,
  reminders: z.object({
    calendar: SafeNonNegativeIntegerSchema,
    sms: SafeNonNegativeIntegerSchema
  }),
  seenProductPages: LiveReplayIntegerCountsDTOSchema
});
const TrafficStatisticsDTOSchema = z.object({
  averageAttendancePercentage: LiveReplayCountsDTOSchema,
  averageAttendanceTime: LiveReplayIntegerCountsDTOSchema,
  deviceCounts: z.object({
    desktop: SafeNonNegativeIntegerSchema,
    mobile: SafeNonNegativeIntegerSchema,
    tablet: SafeNonNegativeIntegerSchema
  }),
  maxSimultaneousViewers: SafeNonNegativeIntegerSchema,
  totalAttendanceTime: LiveReplayIntegerCountsDTOSchema,
  totalLiveDuration: SafeNonNegativeIntegerSchema,
  views: LiveReplayIntegerCountsDTOSchema,
  uniqueViewers: LiveReplayIntegerCountsDTOSchema
});
const ProductStatisticsWithoutSalesDTOSchema = z.object({
  cart: SafeNonNegativeIntegerSchema,
  validated: SafeNonNegativeIntegerSchema,
  views: SafeNonNegativeIntegerSchema
});
const ProductStatisticsWithSalesDTOSchema = ProductStatisticsWithoutSalesDTOSchema.extend({
  purchased: SafeNonNegativeIntegerSchema
});
z.union([
  ProductStatisticsWithoutSalesDTOSchema,
  ProductStatisticsWithSalesDTOSchema
]);
const ProductsStatisticsWithSalesDTOSchema = z.record(ProductStatisticsWithSalesDTOSchema);
const ProductsStatisticsWithoutSalesDTOSchema = z.record(ProductStatisticsWithoutSalesDTOSchema);
z.union([
  ProductsStatisticsWithoutSalesDTOSchema,
  ProductsStatisticsWithSalesDTOSchema
]);
const EventStatisticsDTOWithoutSalesSchema = z.object({
  eCommerce: ECommerceStatisticsDTOSchema,
  engagement: EngagementStatisticsDTOSchema,
  traffic: TrafficStatisticsDTOSchema,
  productsStatistics: ProductsStatisticsWithoutSalesDTOSchema
});
const EventStatisticsDTOWithSalesSchema = z.object({
  eCommerce: ECommerceStatisticsWithSalesDTOSchema,
  engagement: EngagementStatisticsDTOSchema,
  traffic: TrafficStatisticsDTOSchema,
  productsStatistics: ProductsStatisticsWithSalesDTOSchema
});
const EventStatisticsDTOSchema = z.union([
  EventStatisticsDTOWithoutSalesSchema,
  EventStatisticsDTOWithSalesSchema
]);
const EventStatisticsPreviewDTOSchema = EventDTOSchema.pick({
  id: true,
  status: true,
  title: true
}).extend({
  productsCount: SafeNonNegativeIntegerSchema,
  imageUrl: URLSchema,
  showTime: z.string().datetime()
});
z.object({
  accessToken: StringSchema
});
const HomePageBaseDTOSchema = z.object({
  productsCount: SafeNonNegativeIntegerSchema,
  productPagesWithMediaCollectionsCount: SafeNonNegativeIntegerSchema,
  publishedShoppablesCount: SafeNonNegativeIntegerSchema,
  upcomingEvent: EventDepictionDTOSchema.nullable(),
  views: z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema,
    shoppableVideos: SafeNonNegativeIntegerSchema
  })
});
const HomepageWithSalesDTOSchema = HomePageBaseDTOSchema.extend({
  purchasedCarts: z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema,
    shoppableVideos: SafeNonNegativeFloatSchema
  }),
  purchasedAmounts: z.object({
    live: SafeNonNegativeFloatSchema,
    replay: SafeNonNegativeFloatSchema,
    shoppableVideos: SafeNonNegativeFloatSchema
  })
});
const HomepageWithoutSalesDTOSchema = HomePageBaseDTOSchema.extend({
  validatedCarts: z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema
  }),
  validatedCartsAmounts: z.object({
    live: SafeNonNegativeFloatSchema,
    replay: SafeNonNegativeFloatSchema
  })
});
z.union([HomepageWithSalesDTOSchema, HomepageWithoutSalesDTOSchema]);
const KlaviyoListDTOSchema = z.object({
  id: StringSchema,
  name: StringSchema
});
const LiveProductStatisticsDTOSchema = z.object({
  countLiveCartQuantity: SafeNonNegativeIntegerSchema,
  // as countLiveCartQuantity but for the actual product
  nbProductPageViews: SafeNonNegativeIntegerSchema,
  totalLiveCartAmount: SafeNonNegativeFloatSchema
});
const LiveProductsStatisticsDTOSchema = z.record(UUIDSchema, LiveProductStatisticsDTOSchema);
z.object({
  byProductIds: LiveProductsStatisticsDTOSchema,
  // number of viewers having sent at least 1 message
  countActiveChatUsers: SafeNonNegativeIntegerSchema,
  // number of viewers actually watching the live
  countActiveViewers: SafeNonNegativeIntegerSchema,
  // number of unique viewers having started the player
  totalLiveUniqueViewers: SafeNonNegativeIntegerSchema,
  countLiveLikes: SafeNonNegativeIntegerSchema,
  // live total product quantity in all users carts (add to cart + quantity updates).
  // if a user leaves, we keep the last value.
  // if a user removes a product, count quantity becomes 0.
  countLiveCartQuantity: SafeNonNegativeIntegerSchema,
  countLiveProductPageOpened: SafeNonNegativeIntegerSchema,
  countLiveValidateCart: SafeNonNegativeIntegerSchema,
  // live total amount of product prices from countLiveCartQuantity
  totalLiveCartAmount: SafeNonNegativeFloatSchema,
  totalLivePurchasedAmount: SafeNonNegativeFloatSchema
});
const BaseMagicContentDTOSchema = z.object({
  createdAt: z.string().datetime(),
  id: StringSchema,
  mediaId: UUIDSchema,
  tenantId: UUIDSchema
});
const MagicContentPendingDTOSchema = BaseMagicContentDTOSchema.extend({
  status: z.literal("pending")
});
const MagicContentSucceedDTOSchema = BaseMagicContentDTOSchema.extend({
  content_html: StringSchema,
  meta_description_snippet: StringSchema,
  title: StringSchema,
  status: z.literal("succeed"),
  updatedAt: z.string().datetime()
});
const MagicContentFailedDTOSchema = BaseMagicContentDTOSchema.extend({
  error: z.string(),
  status: z.literal("failed"),
  updatedAt: z.string().datetime()
});
const MagicContentDTOSchema = z.discriminatedUnion("status", [
  MagicContentPendingDTOSchema,
  MagicContentSucceedDTOSchema,
  MagicContentFailedDTOSchema
]);
const GenerateMagicContentResponseDTOSchema = z.object({
  id: UUIDSchema
});
const GetMagicContentsDTOSchema = z.object({
  magicContents: z.array(MagicContentDTOSchema),
  transcriptAvailable: z.boolean()
});
const UpdateProductOverrideDTOSchema = z.object({
  description: StringNullableSchema,
  imageUrls: z.array(URLSchema).nullable(),
  title: StringNullableSchema
});
const CreateEmailReminderDTOSchema = z.object({
  email: StringSchema.email(),
  eventURL: URLSchema,
  locale: StringSchema
});
const EventTrafficReportDTOSchema = z.object({
  nbUniqueLiveViewers: z.number(),
  nbUniqueReplayViewers: z.number(),
  showTime: z.string().datetime(),
  title: z.string()
});
const ShoppableTrafficReportDTOSchema = EventTrafficReportDTOSchema.omit({
  showTime: true,
  nbUniqueLiveViewers: true,
  nbUniqueReplayViewers: true
}).extend({
  nbUniqueShoppableVideoViewers: z.number(),
  productTitle: StringSchema
});
const TrafficReportDTOSchema = z.union([EventTrafficReportDTOSchema, ShoppableTrafficReportDTOSchema]);
const MonthTrafficReportsDTOSchema = z.record(UUIDSchema, TrafficReportDTOSchema);
const MonthNumberSchema = z.number().int().min(0).max(11);
z.record(MonthNumberSchema, MonthTrafficReportsDTOSchema);
const EventBillingReportDTOSchema = z.object({
  liveUsageInMin: z.number().or(z.literal("NA")),
  nbSentSMS: z.number().or(z.literal("NA")),
  replayUsageInMin: z.number().or(z.literal("NA")),
  showTime: z.string().datetime(),
  title: StringSchema
});
const MonthBillingReportsDTOSchema = z.record(UUIDSchema, EventBillingReportDTOSchema);
z.record(MonthNumberSchema, MonthBillingReportsDTOSchema);
const HomePage = z.object({
  type: z.literal("home")
});
const ProductPage = z.object({
  id: StringSchema,
  name: StringSchema,
  type: z.literal("product")
});
const CollectionPage = z.object({
  id: StringSchema,
  name: StringSchema,
  type: z.literal("collection")
});
const PagePage = z.object({
  id: StringSchema,
  name: StringSchema,
  type: z.literal("page")
});
const ShopifyPageDTOSchema = z.union([HomePage, ProductPage, CollectionPage, PagePage]);
z.object({ pages: ShopifyPageDTOSchema.array() });
const ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema = z.object({
  id: UUIDSchema,
  imageUrl: URLNullableSchema,
  productTitle: StringSchema,
  totalAttendanceTime: SafeNonNegativeIntegerSchema,
  title: StringSchema,
  views: SafeNonNegativeIntegerSchema
});
const ShoppableVideoStatisticsPreviewWithSalesDTOSchema = ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema.extend({
  purchasedAmounts: SafeNonNegativeFloatSchema,
  purchasedCarts: SafeNonNegativeIntegerSchema
});
const LiveReplayShoppableCountsDTOSchema = LiveReplayIntegerCountsDTOSchema.extend({
  shoppable: SafeNonNegativeIntegerSchema
});
const BaseTenantStatisticsDTOSchema = z.object({
  events: z.object({
    engagement: EngagementStatisticsDTOSchema,
    eventStatisticsPreviews: EventStatisticsPreviewDTOSchema.array(),
    liveCount: SafeNonNegativeIntegerSchema,
    traffic: TrafficStatisticsDTOSchema.extend({
      views: LiveReplayIntegerCountsDTOSchema
    })
  }),
  shoppables: z.object({
    traffic: z.object({
      totalAttendanceTime: SafeNonNegativeIntegerSchema,
      views: SafeNonNegativeIntegerSchema
    })
  })
});
const TenantStatisticsWithSalesDTOSchema = BaseTenantStatisticsDTOSchema.extend({
  events: BaseTenantStatisticsDTOSchema.shape.events.extend({
    eCommerce: ECommerceStatisticsDTOSchema.extend({
      purchasedAmounts: LiveReplayCountsDTOSchema,
      purchasedCarts: LiveReplayIntegerCountsDTOSchema
    })
  }),
  shoppables: BaseTenantStatisticsDTOSchema.shape.shoppables.extend({
    eCommerce: z.object({
      purchasedAmounts: SafeNonNegativeFloatSchema,
      purchasedCarts: SafeNonNegativeIntegerSchema
    }),
    shoppableVideoStatisticsPreviews: ShoppableVideoStatisticsPreviewWithSalesDTOSchema.array()
  })
});
const TenantStatisticsWithoutSalesDTOSchema = BaseTenantStatisticsDTOSchema.extend({
  events: BaseTenantStatisticsDTOSchema.shape.events.extend({
    eCommerce: ECommerceStatisticsDTOSchema
  }),
  shoppables: BaseTenantStatisticsDTOSchema.shape.shoppables.extend({
    shoppableVideoStatisticsPreviews: ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema.array()
  })
});
z.union([TenantStatisticsWithSalesDTOSchema, TenantStatisticsWithoutSalesDTOSchema]);
const constraints$2 = {
  events: constraints$7,
  landingPage: constraints$6,
  mediaCollections: constraints$5,
  products: constraints$8,
  shoppableVideos: constraints$4,
  tenants: constraints$3
};
const index$4 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  AddCatalogProductsToEventDTOSchema,
  AuthenticateChatGuestDTOSchema,
  BaseMagicContentDTOSchema,
  ChatCredentialsDTOSchema,
  CreateEmailReminderDTOSchema,
  CreateEventDTOSchema,
  CreateMediaCollectionDTOSchema,
  CreateProductDTOSchema,
  CreateShoppableVideoDTOSchema,
  CreateTenantDTOSchema,
  CurrencyDTOSchema,
  CurrencySchema,
  CustomiseEventThemeDTOSchema,
  DisplayProductDTOSchema,
  ECommerceStatisticsDTOSchema,
  EngagementStatisticsDTOSchema,
  EventDTOSchema,
  EventDepictionDTOSchema,
  EventStatisticsDTOSchema,
  EventStatisticsDTOWithSalesSchema,
  EventStatisticsDTOWithoutSalesSchema,
  EventStatisticsPreviewDTOSchema,
  EventThemeDTOSchema,
  FacebookRestreamingAccountType,
  FacebookRestreamingAccountTypeSchema,
  FacebookRestreamingConfigurationDTOSchema,
  GenerateMagicContentResponseDTOSchema,
  GetMagicContentsDTOSchema,
  GetOneMediaCollectionPublicDTOSchema,
  GetVideoFromSocialMediaUrlDTOSchema,
  GetVideoFromSocialMediaUrlResponseDTOSchema,
  HasCmsLandingPageDTOSchema: HasCmsLandingPageDTOSchema$1,
  IntegrationsDTOSchema,
  KlaviyoListDTOSchema,
  LandingPageDTOSchema: LandingPageDTOSchema$1,
  LandingPageIdSchema: LandingPageIdSchema$1,
  LandingPageWithPublicEventDepictionsDTOSchema: LandingPageWithPublicEventDepictionsDTOSchema$1,
  LiveReplayCountsDTOSchema,
  LiveReplayIntegerCountsDTOSchema,
  LiveReplayShoppableCountsDTOSchema,
  MagicContentDTOSchema,
  MagicContentFailedDTOSchema,
  MagicContentPendingDTOSchema,
  MagicContentSucceedDTOSchema,
  MediaCollectionMediaDTOSchema,
  MediaCollectionMediaPublicDTOSchema,
  PinMessageDTOSchema,
  PinnedMessageDTOSchema,
  ProductDTOSchema,
  PublicEventDepictionDTOSchema,
  ReorderProductsLineupDTOSchema,
  ReplayFileUploadUrlsDTOSchema,
  ReplaysChaptersDTOSchema,
  RestreamingConfigurationDTOSchema,
  RestreamingPlatformSchema,
  ShoppableThemeFileUploadUrlsDTOSchema,
  ShoppableVideoDTOSchema,
  ShoppableVideoFileUploadCompletedDTOSchema,
  ShoppableVideoFileUploadUrlDTOSchema,
  ShoppableVideoStatisticsPreviewWithSalesDTOSchema,
  ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema,
  ShoppableVideoStatus,
  ShoppableVideoStatusSchema,
  ShoppableVideoThumbnailFileUploadUrlDTOSchema,
  ShoppablesSettingsDTOSchema,
  TenantDTOSchema,
  ThemeFileUploadUrlsDTOSchema,
  ToggleLiveViewersCountDisplayDTOSchema,
  TrafficStatisticsDTOSchema,
  UnpinMessageDTOSchema,
  UpdateCurrencyDTOSchema,
  UpdateEventDTOSchema,
  UpdateEventViewersCountDTOSchema,
  UpdateIntegrationsDTOSchema,
  UpdateLandingPageDTOSchema: UpdateLandingPageDTOSchema$1,
  UpdateMediaCollectionPagesDTOSchema,
  UpdateMediaCollectionSettingsDTOSChema,
  UpdateProductDTOSchema,
  UpdateProductOverrideDTOSchema,
  UpdateReplayDTOSchema,
  UpdateShoppableVideoDTOSchema,
  UpdateShoppablesSettingsDTOSchema,
  VideoStreamChannelDTOSchema,
  ViewerTokenDTOSchema,
  YoutubeRestreamingConfigurationDTOSchema,
  constraints: constraints$2
}, Symbol.toStringTag, { value: "Module" }));
const ProductVariantStateSchema = z.object({
  coverUrl: URLSchema.nullable(),
  crossedOutPrice: z.number().nullable(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema
});
const ProductOptionValueStateSchema = z.object({
  id: UUIDSchema,
  name: StringSchema
});
const CBOEventReadModelDTOProductOptionSchema = z.object({
  allValues: ProductOptionValueStateSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: ProductOptionValueStateSchema.array(),
  type: StringSchema
});
const CBOEventReadModelDTOProductOptionsSchema = z.object({
  option1: CBOEventReadModelDTOProductOptionSchema,
  option2: CBOEventReadModelDTOProductOptionSchema.nullable(),
  option3: CBOEventReadModelDTOProductOptionSchema.nullable(),
  variants: ProductVariantStateSchema.array(),
  variantsHaveSamePrice: z.boolean()
});
const OverrideSyncedProductFieldsSchema = z.object({
  description: StringNullableSchema,
  imageUrls: URLSchema.array().nullable(),
  title: StringNullableSchema
});
ProductDTOSchema$1.pick({
  description: true,
  imageUrls: true,
  title: true
});
const CBOEventReadModelDTOProductBaseSchema = ProductDTOSchema$1.omit({
  id: true,
  inventoryPolicy: true,
  inventoryQuantity: true,
  options: true
}).extend({
  id: UUIDSchema,
  options: CBOEventReadModelDTOProductOptionsSchema.nullable()
});
const CBOEventReadModelDTOSyncedProductSchema = CBOEventReadModelDTOProductBaseSchema.extend({
  isSynced: z.literal(true),
  overriddenFields: OverrideSyncedProductFieldsSchema
});
const CBOEventReadModelDTONotSyncedProductSchema = CBOEventReadModelDTOProductBaseSchema.extend({
  isSynced: z.literal(false)
});
const CBOEventReadModelDTOProductSchema = z.union([
  CBOEventReadModelDTOSyncedProductSchema,
  CBOEventReadModelDTONotSyncedProductSchema
]);
const CBOEventReadModelDTOPinnedChatMessageSchema = z.object({
  content: StringSchema,
  messageId: StringSchema
});
const CBOEventReadModelDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  chatModeratorIds: StringSchema.array(),
  createdAt: StringSchema,
  deletedAt: StringSchema.datetime().nullable(),
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  finishTime: StringSchema.datetime().nullable(),
  id: UUIDSchema,
  isLiveViewersCountDisplayed: z.boolean(),
  moderatorMessageLabel: StringSchema,
  pinnedChatMessage: CBOEventReadModelDTOPinnedChatMessageSchema.nullable(),
  postEventText: StringSchema,
  preEventText: StringSchema,
  products: CBOEventReadModelDTOProductSchema.array(),
  publisherPhone: StringSchema,
  replayFileUrl: URLNullableSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime().nullable(),
  status: z.nativeEnum(EventStatus),
  tenantId: UUIDSchema,
  theme: EventThemeDTOSchema,
  title: StringSchema,
  videoStreamChannel: VideoStreamChannelDTOSchema,
  videoDuration: z.number().nullable(),
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable()
});
const SynchronizeEventListReadModelResponseDTOSchema = CBOEventReadModelDTOSchema.pick({
  id: true,
  publisherPhone: true,
  replayFileUrl: true,
  showTime: true,
  status: true,
  title: true,
  videoDuration: true
}).extend({
  image: StringSchema,
  productsIds: UUIDSchema.array(),
  remindersCount: z.number()
});
const CreateEventReminderDTOSchema = z.object({
  commercialInfoConsent: z.boolean(),
  eventURL: StringSchema,
  firstName: StringSchema,
  locale: StringSchema,
  phoneNumber: StringSchema
});
const MessageDataSchema = z.record(z.any());
const allowedDataContentTypes = ["application/json;charset=utf-8"];
const allowedMessageSources = [
  "api",
  "player",
  "event-platform",
  "landing-page",
  "replay-processing",
  "shopify"
];
const MessageTypes = {
  Landing_Page_Reminder_Created: "io.bellepoque.landing_page.reminder.Created",
  Player_Cart_ProductAdded: "io.bellepoque.player.cart.ProductAdded",
  Player_Cart_ProductRemoved: "io.bellepoque.player.cart.ProductRemoved",
  Player_Cart_QuantityUpdated: "io.bellepoque.player.cart.QuantityUpdated",
  Player_Cart_Validated: "io.bellepoque.player.cart.Validated",
  Player_Chat_Joined: "io.bellepoque.player.chat.Joined",
  Player_Chat_Liked: "io.bellepoque.player.chat.Liked",
  Player_Chat_MessagePinned: "io.bellepoque.player.chat.MessagePinned",
  Player_Chat_MessageSent: "io.bellepoque.player.chat.MessageSent",
  Player_Chat_MessageUnpinned: "io.bellepoque.player.chat.MessageUnpinned",
  Player_Player_Ping: "io.bellepoque.player.player.Ping",
  Player_Player_PlayerStarted: "io.bellepoque.player.player.PlayerStarted",
  Player_Player_PlayerStopped: "io.bellepoque.player.player.PlayerStopped",
  Player_Reminder_Created: "io.bellepoque.player.reminder.Created",
  Player_UI_ProductPageOpened: "io.bellepoque.player.ui.ProductPageOpened",
  Server_EventManagement_CatalogProductsAddedToEvent: "io.bellepoque.server.event_management.CatalogProductsAddedToEvent",
  Server_EventManagement_EventCreated: "io.bellepoque.server.event_management.EventCreated",
  Server_EventManagement_EventDeleted: "io.bellepoque.server.event_management.EventDeleted",
  Server_EventManagement_EventProductDeleted: "io.bellepoque.server.event_management.EventProductDeleted",
  Server_EventManagement_EventProductUpdated: "io.bellepoque.server.event_management.EventProductUpdated",
  Server_EventManagement_EventProductsReordered: "io.bellepoque.server.event_management.EventProductsReordered",
  Server_EventManagement_EventPublished: "io.bellepoque.server.event_management.EventPublished",
  Server_EventManagement_EventUnPublished: "io.bellepoque.server.event_management.EventUnPublished",
  Server_EventManagement_EventUpdated: "io.bellepoque.server.event_management.EventUpdated",
  Server_EventManagement_LiveStarted: "io.bellepoque.server.event_management.LiveStarted",
  Server_EventManagement_LiveStopped: "io.bellepoque.server.event_management.LiveStopped",
  Server_EventManagement_ProductAddedToEvent: "io.bellepoque.server.event_management.ProductAddedToEvent",
  Server_EventManagement_ProductDisplayed: "io.bellepoque.server.event_management.ProductDisplayed",
  Server_EventManagement_ProductOverrideDeletedFromEvents: "io.bellepoque.server.event_management.ProductOverrideDeletedFromEvents",
  Server_EventManagement_ReplayOpened: "io.bellepoque.server.event_management.ReplayOpened",
  Server_EventManagement_ReplayPublished: "io.bellepoque.server.event_management.ReplayPublished",
  Server_EventManagement_ReplayUpdated: "io.bellepoque.server.event_management.ReplayUpdated",
  Server_EventManagement_ReplayReadModelUpdated: "io.bellepoque.server.event_management.ReplayReadModelUpdated",
  Server_IAM_MasterAddedToTenant: "io.bellepoque.server.iam.MasterAddedToTenant",
  Server_IAM_TenantCreated: "io.bellepoque.server.iam.TenantCreated",
  Server_IAM_TenantIntegrationsUpdated: "io.bellepoque.server.iam.TenantIntegrationsUpdated",
  Server_MediaCollectionManagement_MediaCollectionCreated: "io.bellepoque.server.media_collection_management.MediaCollectionCreated",
  Server_MediaCollectionManagement_MediaCollectionDeleted: "io.bellepoque.server.media_collection_management.MediaCollectionDeleted",
  Server_MediaCollectionManagement_MediaCollectionPagesUpdated: "io.bellepoque.server.media_collection_management.MediaCollectionPagesUpdated",
  Server_MediaCollectionManagement_MediaCollectionSettingsUpdated: "io.bellepoque.server.media_collection_management.MediaCollectionSettingsUpdated",
  Server_ProductManagement_CatalogToImportCreated: "io.bellepoque.server.product_management.CatalogToImportCreated",
  Server_ProductManagement_SyncedCatalogProductActivated: "io.bellepoque.server.product_management.SyncedCatalogProductActivated",
  Server_ProductManagement_SyncedCatalogProductCreated: "io.bellepoque.server.product_management.SyncedCatalogProductCreated",
  Server_ProductManagement_SyncedCatalogProductDeactivated: "io.bellepoque.server.product_management.SyncedCatalogProductDeactivated",
  Server_ProductManagement_SyncedCatalogProductUpdated: "io.bellepoque.server.product_management.SyncedCatalogProductUpdated",
  Server_Replaying_AudioTranscriptionFailed: "io.bellepoque.server.replaying.AudioTranscriptionFailed",
  Server_Replaying_AudioTranscriptionSucceeded: "io.bellepoque.server.replaying.AudioTranscriptionSucceeded",
  Server_Replaying_ReplayFailed: "io.bellepoque.server.replaying.ReplayFailed",
  Server_Replaying_ResyncReplayCommand: "io.bellepoque.server.replaying.ResyncReplayCommand",
  Server_Replaying_Mp4Published: "io.bellepoque.server.replaying.Mp4Published",
  Server_Replaying_VideoProcessingRequested: "io.bellepoque.server.replaying.VideoProcessingRequested",
  Server_ShoppableVideoManagement_ShoppablesSettingsUpdated: "io.bellepoque.server.shoppable_video_management.ShoppablesSettingsUpdated",
  Server_ShoppableVideoManagement_ShoppableVideoCreated: "io.bellepoque.server.shoppable_video_management.ShoppableVideoCreated",
  Server_ShoppableVideoManagement_ShoppableVideoDeleted: "io.bellepoque.server.shoppable_video_management.ShoppableVideoDeleted",
  Server_ShoppableVideoManagement_ShoppableVideoFileUploaded: "io.bellepoque.server.shoppable_video_management.ShoppableVideoFileUploaded",
  Server_ShoppableVideoManagement_ShoppableVideoPublicationRequested: "io.bellepoque.server.shoppable_video_management.ShoppableVideoPublicationRequested",
  Server_ShoppableVideoManagement_ShoppableVideoUnpublicationRequested: "io.bellepoque.server.shoppable_video_management.ShoppableVideoUnpublicationRequested",
  Server_ShoppableVideoManagement_ShoppableVideoUpdated: "io.bellepoque.server.shoppable_video_management.ShoppableVideoUpdated",
  Server_ShoppableVideoManagement_ShoppableVideoProcessed: "io.bellepoque.server.shoppable_video_management.ShoppableVideoProcessed",
  Server_ShoppableVideoManagement_ShoppableVideoProcessingFailed: "io.bellepoque.server.shoppable_video_management.ShoppableVideoProcessingFailed",
  ReplayProcessing_VideoProcessingCompleted: "io.bellepoque.replay_processing.VideoProcessingCompleted",
  ReplayProcessing_VideoProcessingFailed: "io.bellepoque.replay_processing.VideoProcessingFailed",
  ReplayProcessing_VideoProcessedHls: "io.bellepoque.replay_processing.VideoProcessedHls",
  ReplayProcessing_VideoProcessedMp4: "io.bellepoque.replay_processing.VideoProcessedMp4",
  ReplayProcessing_AudioProcessed: "io.bellepoque.replay_processing.AudioProcessed",
  Server_Statistics_EventStatisticsUpdated: "io.bellepoque.server.statistics.EventStatisticsUpdated",
  Server_Statistics_PeriodicTenantSalesCalculated: "io.bellepoque.server.statistics.PeriodicTenantSalesCalculated",
  Server_Statistics_PlayerStartedCreated: "io.bellepoque.server.statistics.PlayerStartedCreated",
  Server_Statistics_ReminderCreated: "io.bellepoque.server.statistics.ReminderCreated",
  Server_Statistics_ReplayEventStatisticsUpdated: "io.bellepoque.server.statistics.ReplayEventStatisticsUpdated",
  Server_Statistics_TenantsActivityReportsGenerated: "io.bellepoque.server.statistics.TenantsActivityReportsGenerated",
  Server_Statistics_VodRankingsGenerated: "io.bellepoque.server.statistics.VodRankingsGenerated",
  Shopify_AppInstalled: "io.bellepoque.shopify.AppInstalled",
  Shopify_AppUninstalled: "io.bellepoque.shopify.AppUninstalled",
  Shopify_ExportCompleted: "io.bellepoque.shopify.ExportCompleted",
  Shopify_StoreClosed: "io.bellepoque.shopify.StoreClosed",
  Shopify_SubscriptionCancelled: "io.bellepoque.shopify.SubscriptionCancelled",
  Shopify_SubscriptionCreated: "io.bellepoque.shopify.SubscriptionCreated",
  ShopifyPlugin_ProductManagement_ProductUpserted: "io.bellepoque.shopify.product_management.ProductUpserted"
};
const MessageTypesSchema = z.nativeEnum(MessageTypes);
const StatisticEventMessageDataSchema = z.object({
  eventId: UUIDSchema,
  status: z.nativeEnum(EventStatus),
  timecode: z.number().nonnegative().finite().safe().optional()
}).catchall(z.any());
const StatisticShoppableMessageDataSchema = z.object({
  shoppableId: UUIDSchema
}).catchall(z.any());
const MessageSchema = z.object({
  datacontenttype: z.enum(allowedDataContentTypes),
  id: z.string(),
  partitionkey: z.string().optional(),
  source: z.enum(allowedMessageSources),
  time: z.string().datetime(),
  type: MessageTypesSchema
});
const StatisticEventMessageSchema = MessageSchema.extend({ data: StatisticEventMessageDataSchema });
const StatisticShoppableMessageSchema = MessageSchema.extend({ data: StatisticShoppableMessageDataSchema });
const StatisticMessageSchema = z.union([StatisticEventMessageSchema, StatisticShoppableMessageSchema]);
const ShopifyAppUninstalledDataSchema = z.object({
  storeId: StringSchema
});
MessageSchema.extend({ data: ShopifyAppUninstalledDataSchema });
class ShopifyAppUninstalled {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_AppUninstalled
    };
  }
}
class ShopifyExportCompleted {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_ExportCompleted
    };
  }
}
const ShopifyAppInstalledDataSchema = z.object({
  storeCurrencyCode: StringSchema,
  storeCountryCode: StringNullableSchema,
  storeId: StringSchema,
  storeEmail: StringSchema,
  storeName: StringSchema
});
MessageSchema.extend({ data: ShopifyAppInstalledDataSchema });
class ShopifyAppInstalled {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_AppInstalled
    };
  }
}
const ShopifySubscriptionCancelledDataSchema = z.object({
  storeId: StringSchema,
  subscriptionId: StringSchema
});
MessageSchema.extend({ data: ShopifySubscriptionCancelledDataSchema });
class ShopifySubscriptionCancelled {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_SubscriptionCancelled
    };
  }
}
const ShopifySubscriptionCreatedDataSchema = z.object({
  storeId: StringSchema,
  planId: BillingPlanIdSchema,
  subscriptionId: StringSchema
});
MessageSchema.extend({ data: ShopifySubscriptionCreatedDataSchema });
class ShopifySubscriptionCreated {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_SubscriptionCreated
    };
  }
}
const ShopifyStoreClosedDataSchema = z.object({
  storeId: StringSchema
});
MessageSchema.extend({ data: ShopifyStoreClosedDataSchema });
class ShopifyStoreClosed {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.storeId,
      source: "shopify",
      time: this.time,
      type: MessageTypes.Shopify_StoreClosed
    };
  }
}
class VideoProcessedHls {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.eventId,
      source: "replay-processing",
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessedHls
    };
  }
}
class VideoProcessedMp4 {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.eventId,
      source: "replay-processing",
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessedMp4
    };
  }
}
class AudioProcessed {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.vodId,
      source: "replay-processing",
      time: this.time,
      type: MessageTypes.ReplayProcessing_AudioProcessed
    };
  }
}
class VideoProcessingCompleted {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.eventId,
      source: "replay-processing",
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessingCompleted
    };
  }
}
class VideoProcessingFailed {
  constructor(id, time, data) {
    this.id = id;
    this.time = time;
    this.data = data;
  }
  toJSON() {
    return {
      data: this.data,
      datacontenttype: "application/json;charset=utf-8",
      id: this.id,
      partitionkey: this.data.eventId,
      source: "replay-processing",
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessingFailed
    };
  }
}
const OutboxMessageSchema = z.object({
  data: z.record(z.string(), z.any()),
  datacontenttype: z.enum(allowedDataContentTypes),
  id: z.string().uuid(),
  partitionkey: z.string().optional(),
  source: z.enum(allowedMessageSources),
  time: z.string().datetime(),
  type: MessageTypesSchema
});
const OutboxItemStateSchema = z.object({
  createdAt: CoerceNotNullDateSchema,
  message: OutboxMessageSchema,
  id: z.string().uuid(),
  sentAt: CoerceNullableDateSchema
});
const NotYetSentOutboxItemStateSchema = OutboxItemStateSchema.merge(
  z.object({
    sentAt: z.null()
  })
);
const SentOutboxItemStateSchema = OutboxItemStateSchema.merge(
  z.object({
    sentAt: z.date()
  })
);
const PinnedMessageSchema = z.object({
  content: StringSchema,
  id: UUIDSchema,
  messageId: StringSchema,
  pinnedAt: CoerceNotNullDateSchema,
  unpinnedAt: CoerceNullableDateSchema
});
const EventMetadataCurrencySchema = z.object({
  code: StringSchema,
  decimalDigits: SafeNonNegativeIntegerSchema,
  id: UUIDSchema,
  symbol: StringSchema
});
const EventMetadataSchema = EventDTOSchema.pick({
  chatIntroductionText: true,
  description: true,
  id: true,
  moderatorMessageLabel: true,
  postEventText: true,
  preEventText: true,
  tenantId: true,
  theme: true,
  title: true
}).extend({
  currency: EventMetadataCurrencySchema
});
const FIRST_LIVE_REPLAY_PING_DELAY = 5e3;
const LIVE_REPLAY_PING_INTERVAL = 1e4;
const FIRST_SHOPPABLE_VIDEO_PING_DELAY = 1e3;
const SHOPPABLE_VIDEO_PING_INTERVAL = 2e3;
const LiveShowPinnedMessageSchema = PinnedMessageSchema.pick({
  content: true,
  messageId: true
}).extend({
  pinnedAt: StringSchema.datetime(),
  unpinnedAt: StringSchema.datetime().nullable()
});
const ProductHighlightSchema = z.object({
  productIds: StringSchema.array(),
  durationFromStart: SafeNonNegativeIntegerSchema
});
const ProductAddedChatMessageMetadata = z.object({
  productId: UUIDSchema,
  type: z.literal("productAdded")
});
const ChatMessageMetadata = ProductAddedChatMessageMetadata;
const ChatMessageSchema = z.object({
  content: StringSchema,
  durationFromStart: SafeNonNegativeIntegerSchema,
  messageId: StringSchema,
  metadata: ChatMessageMetadata.nullable(),
  senderId: StringSchema,
  senderNickname: StringSchema
});
const ChapterSchema = z.object({
  productId: StringSchema,
  durationFromStart: SafeNonNegativeIntegerSchema,
  endAt: SafeNonNegativeFloatSchema
});
const ReplayPinnedMessageSchema = z.object({
  content: StringSchema,
  messageId: StringSchema,
  pinnedAt: SafeNonNegativeIntegerSchema,
  unpinnedAt: SafeNonNegativeIntegerSchema.nullable()
});
const PlannedLiveShowSchema = EventMetadataSchema.extend({
  showTime: StringSchema.datetime(),
  status: z.literal(EventStatus.PLANNED)
});
const PrivatePreviewLiveShowSchema = EventMetadataSchema.extend({
  chatModeratorIds: StringSchema.array(),
  pinnedChatMessages: LiveShowPinnedMessageSchema.array().nullable(),
  productHighlights: StringSchema.array(),
  products: ProductDTOSchema$1.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime().nullable(),
  status: z.literal(EventStatus.PRIVATE_PREVIEW),
  viewersCount: z.number().nullable().nullable()
});
const OnAirLiveShowSchema = EventMetadataSchema.extend({
  chatModeratorIds: StringSchema.array(),
  pinnedChatMessages: LiveShowPinnedMessageSchema.array().nullable(),
  productHighlights: StringSchema.array(),
  products: ProductDTOSchema$1.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime(),
  status: z.literal(EventStatus.ON_AIR),
  streamToken: StringSchema,
  viewersCount: z.number().nullable()
});
const FinishedLiveShowSchema = EventMetadataSchema.extend({
  products: ProductDTOSchema$1.array(),
  status: z.literal(EventStatus.FINISHED)
});
const ReplayMetadataSchema = EventMetadataSchema.extend({
  chapters: ChapterSchema.array(),
  chatMessages: ChatMessageSchema.array().nullable(),
  chatModeratorIds: StringSchema.array(),
  firstFrameUrl: StringSchema.nullable(),
  isShoppable: z.boolean(),
  pinnedChatMessages: ReplayPinnedMessageSchema.array(),
  productHighlights: ProductHighlightSchema.array(),
  products: ProductDTOSchema$1.array(),
  videoDuration: SafeNonNegativeFloatSchema,
  videoUrl: StringSchema,
  viewersCount: SafeNonNegativeIntegerSchema
});
const ReplayReadyLiveShowSchema = ReplayMetadataSchema.extend({
  status: z.literal(EventStatus.REPLAY_READY)
});
const PrivateReplayLiveShowSchema = ReplayMetadataSchema.extend({
  status: z.literal(EventStatus.PRIVATE_REPLAY)
});
const ReplayOpenedLiveShowSchema = ReplayMetadataSchema.extend({
  status: z.literal(EventStatus.REPLAY),
  isShoppable: z.literal(false)
});
const ReplayLiveShowSchema = z.union([
  ReplayReadyLiveShowSchema,
  PrivateReplayLiveShowSchema,
  ReplayOpenedLiveShowSchema
]);
const ShoppableLiveShowSchema = ReplayMetadataSchema.extend({
  status: z.literal(EventStatus.REPLAY),
  isShoppable: z.literal(true)
});
const TenantPublicRealtimeDataSchema = z.object({
  askEmail: z.boolean(),
  isLMULogoDisplayed: z.boolean(),
  maxLiveDurationInSeconds: SafeNonNegativeIntegerSchema.nullable(),
  tenantId: UUIDSchema
});
const FileProcessingInstructionsSchema = z.object({
  id: z.string(),
  sourceUrl: StringSchema,
  startTime: z.number(),
  videoDuration: z.number()
});
const AutomatedReplayRequestSchema = z.object({
  eventHubName: z.string(),
  files: FileProcessingInstructionsSchema.array(),
  eventId: UUIDSchema,
  profile: z.enum(["hd", "fhd", "fhd++"]),
  makeMp4: z.boolean(),
  makeAudioFile: z.boolean()
});
const ALLOWED_REPLAYS_COLLECTIONS_CMS = [Cms.Shopify];
const OnboardingStep = {
  CreateEvent: "CREATE_EVENT",
  AddProducts: "ADD_PRODUCTS",
  CameraApp: "CAMERA_APP",
  PrivateTest: "PRIVATE_TEST",
  OpenToPublic: "OPEN_TO_PUBLIC",
  Completed: "COMPLETED"
};
const OnboardingProgressSchema = z.object({
  eventId: UUIDSchema.nullable(),
  step: z.nativeEnum(OnboardingStep)
});
const UpdateOnboardingProgressDTOSchema = OnboardingProgressSchema;
const INITIAL_ONBOARDING_PROGRESS = {
  eventId: null,
  step: OnboardingStep.CreateEvent
};
const COMPLETED_ONBOARDING_PROGRESS = {
  eventId: null,
  step: OnboardingStep.Completed
};
const CBOShoppableVideoListItemReadModelDTOSchema = ShoppableVideoDTOSchema.pick({
  createdAt: true,
  id: true,
  status: true,
  thumbnailUrl: true,
  title: true,
  videoDuration: true,
  videoUrl: true
}).merge(
  z.object({
    productId: z.string().uuid(),
    productImage: URLNullableSchema,
    productOriginalId: StringSchema,
    productTitle: z.string()
  })
);
const index$3 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  CBOShoppableVideoListItemReadModelDTOSchema
}, Symbol.toStringTag, { value: "Module" }));
const index$2 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  api: index$4,
  cbo: index$3
}, Symbol.toStringTag, { value: "Module" }));
const constraints$1 = {
  replaySectionTitle: {
    minLength: 0,
    maxLength: 100
  }
};
const HasCmsLandingPageDTOSchema = z.object({
  hasCmsLandingPage: z.boolean()
});
const LandingPageDTOSchema = z.object({
  highlightedEventsIds: z.string().array(),
  landingPageUrl: z.string(),
  publishedReplayEventsIds: z.string().array(),
  replaySectionTitle: z.string()
});
const UpdateLandingPageDTOSchema = LandingPageDTOSchema.omit({
  landingPageUrl: true
});
const LandingPageWithPublicEventDepictionsDTOSchema = z.object({
  askEmail: z.boolean(),
  highlightedEvents: PublicEventDepictionDTOSchema.array(),
  publishedReplayEvents: PublicEventDepictionDTOSchema.array(),
  replaySectionTitle: StringSchema
});
const LandingPageIdSchema = UUIDSchema.brand("LandingPageId");
const constraints = {
  landingPage: constraints$1
};
const index$1 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  HasCmsLandingPageDTOSchema,
  LandingPageDTOSchema,
  LandingPageIdSchema,
  LandingPageWithPublicEventDepictionsDTOSchema,
  UpdateLandingPageDTOSchema,
  constraints
}, Symbol.toStringTag, { value: "Module" }));
const index = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  api: index$1
}, Symbol.toStringTag, { value: "Module" }));
const shopifySaleTrackerTag = "_lmu_video_id";
export {
  ALLOWED_REPLAYS_COLLECTIONS_CMS,
  AudioProcessed,
  AutomatedReplayRequestSchema,
  BILLING_PLANS,
  BillingPlanIdSchema,
  CBOEventReadModelDTOSchema,
  CMS_WITH_SALES_TRACKING,
  COMPLETED_ONBOARDING_PROGRESS,
  ChapterSchema,
  ChatMessageSchema,
  Cms,
  CmsSchema,
  CoerceNotNullDateSchema,
  CoerceNullableDateSchema,
  CreateEventReminderDTOSchema,
  DateLikeSchema,
  Device,
  ErrorCodes,
  ErrorsByCode,
  EventMetadataSchema,
  EventStatus,
  FINISHED_OR_LATER_EVENT_STATUSES,
  FIRST_LIVE_REPLAY_PING_DELAY,
  FIRST_SHOPPABLE_VIDEO_PING_DELAY,
  FROZEN_EVENT_STATUSES,
  FinishedLiveShowSchema,
  FirestoreCollectionNames,
  HAS_BEEN_LIVE_EVENT_STATUSES,
  HIGHLIGHTABLE_STATUSES,
  INITIAL_ONBOARDING_PROGRESS,
  ISO31661Alpha2Schema,
  InventoryPolicySchema,
  LIVE_REPLAY_PING_INTERVAL,
  LiveShowPinnedMessageSchema,
  MessageDataSchema,
  MessageSchema,
  MessageTypes,
  MessageTypesSchema,
  NotYetSentOutboxItemStateSchema,
  OnAirLiveShowSchema,
  OnboardingProgressSchema,
  OnboardingStep,
  OutboxItemStateSchema,
  OutboxMessageSchema,
  PUBLISHABLE_REPLAY_EVENT_STATUSES,
  PinnedMessageSchema,
  PlannedLiveShowSchema,
  PrivatePreviewLiveShowSchema,
  PrivateReplayLiveShowSchema,
  ProductDTOSchema$1 as ProductDTOSchema,
  ProductHighlightSchema,
  ProductOptionsSchema,
  REPLAY_STATUSES,
  ReplayLiveShowSchema,
  ReplayOpenedLiveShowSchema,
  ReplayPinnedMessageSchema,
  ReplayReadyLiveShowSchema,
  SHOPPABLE_VIDEO_PING_INTERVAL,
  SafeNonNegativeFloatSchema,
  SafeNonNegativeIntegerSchema,
  SentOutboxItemStateSchema,
  ShopifyAppInstalled,
  ShopifyAppInstalledDataSchema,
  ShopifyAppUninstalled,
  ShopifyAppUninstalledDataSchema,
  ShopifyExportCompleted,
  ShopifyStoreClosed,
  ShopifyStoreClosedDataSchema,
  ShopifySubscriptionCancelled,
  ShopifySubscriptionCancelledDataSchema,
  ShopifySubscriptionCreated,
  ShopifySubscriptionCreatedDataSchema,
  ShoppableLiveShowSchema,
  StatisticEventMessageDataSchema,
  StatisticEventMessageSchema,
  StatisticMessageSchema,
  StatisticShoppableMessageSchema,
  StringNonEmptySchema,
  StringNullableSchema,
  StringSchema,
  SynchronizeEventListReadModelResponseDTOSchema,
  TEST_OR_LATER_EVENT_STATUSES,
  TenantPublicRealtimeDataSchema,
  UNFINISHED_EVENT_STATUSES,
  URLNullableSchema,
  URLSchema,
  UUIDNullableSchema,
  UUIDSchema,
  UpdateOnboardingProgressDTOSchema,
  index$2 as V1,
  index as V2,
  VideoProcessedHls,
  VideoProcessedMp4,
  VideoProcessingCompleted,
  VideoProcessingFailed,
  allowedDataContentTypes,
  allowedMessageSources,
  shopifySaleTrackerTag
};
